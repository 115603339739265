/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../../components/Header/Header';
import Sidebar from '../../../components/Sidebar/Sidebar';
import NonIDEeditor from '../DSAContent/NonIDEeditor';
import { getSingleDSAProblem, getSingleTheoryProblem, updateTheoryProblem } from '../../../services/operations/new-syllabus';
import { SendToBack, StepBackIcon } from 'lucide-react';
import ContentEdit from '../DSAContent/ContentEdit';
import { Controller, useForm } from 'react-hook-form';
import RichEditor from '../../../components/RichEditor/RichEditor';

const TheoryContentEdit = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [problemNote, setProblemNote] = useState(null);
    const { CurrentProblem } = useSelector((state) => state.new_syllabus);
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        reset,
        control,
        formState: { errors, isDirty, isSubmitted },
    } = useForm({
        defaultValues: {
            problem_name: CurrentProblem?.problem_name || '',
        },
    });

    const { slug } = params;

    useEffect(() => {
        if (slug) {
            dispatch(getSingleTheoryProblem(slug));
        }
    }, [dispatch, slug]);

    useEffect(() => {
        if (Object.keys(CurrentProblem).length > 0) {
            reset({

                problem_name: CurrentProblem.problem_name || '',
                slug: CurrentProblem.slug || '',
                video_link: CurrentProblem.video_link || '',
                content: CurrentProblem.content || '',
            })
        }
    }, [CurrentProblem, reset]);

    const onSubmit = async (data) => {
        const problem_data = {
            id: CurrentProblem.id,
            problem_name: data.problem_name,
            slug: data.slug,
            video_link: data.video_link,
            content: data.content,
        };

        dispatch(updateTheoryProblem(problem_data));
    }


    return (
        <div className='flex  dark:bg-dark_50  h-screen overflow-hidden'>
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className='relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden'>
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

                <main>
                    <div className='px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto'>
                        <div className='mt-4 flex flex-col gap-10'>
                            {/* BACK BUTTON */}
                            <div className='flex items-center gap-4'>
                                <button
                                    onClick={() => navigate(-1)}
                                    className='flex items-center gap-2 px-4 py-2 rounded-md shadow-md'
                                >
                                    <StepBackIcon />
                                    <span>Back</span>
                                </button>
                            </div>

                            <form onSubmit={handleSubmit(onSubmit)}>
                            <div className='flex gap-5 mb-8'>
                                <div className='w-1/2'>
                                    <div className='flex items-center gap-x-4'>
                                        <span className='text-gray-800 dark:text-zinc-300 text-[16px] font-medium'>
                                            Problem Name <span className='text-red-500'>*</span>
                                        </span>
                                        {errors.problem_name && (
                                            <span className='text-red-500'>
                                                Please enter at least 10 characters.
                                            </span>
                                        )}
                                    </div>
                                    <Controller
                                        name='problem_name'
                                        control={control}
                                        render={({ field }) => (
                                            <input
                                                type='text'
                                                name='problem_name'
                                                className='form-input w-full text-gray-300'
                                                placeholder='problem_name'
                                                {...field}
                                            />
                                        )}
                                        rules={{
                                            required: 'problem_name is required',
                                        }}
                                    />
                                </div>

                                <div className='w-1/2'>
                                    <div className='flex items-center gap-x-4'>
                                        <span className='text-gray-800 dark:text-zinc-300 text-[16px] font-medium'>
                                            Slug <span className='text-red-500'>*</span>
                                        </span>
                                        {errors.slug && (
                                            <span className='text-red-500'>
                                                {errors.slug.message}
                                            </span>
                                        )}
                                    </div>
                                    <Controller
                                        name='slug'
                                        control={control}
                                        render={({ field }) => (
                                            <input
                                                type='text'
                                                name='slug'
                                                className='form-input w-full text-gray-300'
                                                placeholder='slug'
                                                {...field}
                                            />
                                        )}
                                        rules={{
                                            required: 'slug is required',
                                        }}
                                    />
                                </div>
                            </div>

                            <div className=''>
                                <div className='flex items-center gap-x-4 mb-2'>
                                    <span className='text-gray-800 dark:text-zinc-300 text-[16px] font-medium'>
                                        Video <span className='text-red-500'>*</span>
                                    </span>
                                    {errors.video_link && (
                                        <span className='text-red-500'>
                                            {errors.video_link.message}
                                        </span>
                                    )}
                                </div>
                                <Controller
                                    name='video_link'
                                    control={control}
                                    render={({ field }) => (
                                        <input
                                            {...field}
                                            type='text'
                                            name='video_link'
                                            className='form-input w-full text-gray-300'
                                            placeholder='video_link'
                                        />
                                    )}
                                />
                            </div>

                            <div className='mb-12 mt-5'>
                                <div className='flex items-center gap-x-4 mb-2'>
                                    <span className='text-gray-800 dark:text-zinc-300 text-[16px] font-medium'>
                                        Content <span className='text-red-500'>*</span>
                                    </span>
                                    {errors.content && (
                                        <span className='text-red-500'>
                                            {errors.content.message}
                                        </span>
                                    )}
                                </div>
                              

                                    <Controller
                                        name='content'
                                        control={control}
                                        render={({ field }) => (
                                           <textarea
                                                name='content'
                                                className='form-input w-full text-gray-300 h-44'
                                                placeholder='content'
                                                {...field}
                                            />
                                           
                                        )}
                                        rules={{
                                            required: 'content is required',
                                        }}
                                    />
                            </div>
                            <button
                                type="submit"
                                className='mt-4 px-4 py-2 rounded-md bg-green-500 text-white'
                            >
                                Submit
                            </button>
                            <button
                                type='button'
                                onClick={() => {
                                    const editorialData = {
                                        problem_name: getValues('problem_name'),
                                        video_link: getValues('video_link'),
                                        content: getValues('content'),
                                    };
                                    localStorage.setItem(
                                        'TheoryContent',
                                        JSON.stringify(editorialData),
                                    );
                                    window.open('/manage-content/theory/preview', '_blank');
                                }}
                                className='mt-4 ml-4 px-4 py-2 rounded-md bg-zinc-600 text-white'
                            >
                                Preview
                            </button>
                            </form>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default TheoryContentEdit;
