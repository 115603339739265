import { apiConnector } from '../apiConnector';
import toast from 'react-hot-toast';
import { DSA, THEORY, QUIZ, TOPICS, SUBJECT_ORDER } from '../api';
import {
    setAllCategories,
    setAllDSAProblems,
    setAllQuizProblems,
    setAllSubCategories,
    setAllSubjectProblems,
    setAllSubjects,
    setAllTheoryProblems,
    setCurrentProblem,
} from '../../slices/new-syllabusSlice';

const {
    GET_ALL_DSA_PROBLEMS,
    GET_SINGLE_DSA_PROBLEMS,
    ADD_DSA_PROBLEM,
    UPDATE_DSA_PROBLEM,
    DELETE_DSA_PROBLEM,
} = DSA;

const {
    GET_ALL_THEORY_PROBLEMS,
    GET_SINGLE_THEORY_PROBLEMS,
    ADD_THEORY_PROBLEM,
    UPDATE_THEORY_PROBLEM,
    DELETE_THEORY_PROBLEM,
} = THEORY;

const {
    GET_ALL_QUIZ_PROBLEMS,
    GET_SINGLE_QUIZ_PROBLEMS,
    ADD_QUIZ_PROBLEM,
    UPDATE_QUIZ_PROBLEM,
    DELETE_QUIZ_PROBLEM,
} = QUIZ;

const {
    GET_ALL_CATEGORIES_V2,
    ADD_CATEGORY_V2,
    UPDATE_CATEGORY_V2,
    DELETE_CATEGORY_V2,

    GET_ALL_SUB_CATEGORIES_V2,
    ADD_SUB_CATEGORY_V2,
    UPDATE_SUB_CATEGORY_V2,
    DELETE_SUB_CATEGORY_V2,

    GET_ALL_TOPICS_V2,
    ADD_TOPIC_V2,
    UPDATE_TOPIC_V2,
    DELETE_TOPIC_V2
} = TOPICS;

const { GET_ALL_SUBJECT_PROBLEMS } = SUBJECT_ORDER;

export function getAllDSAProblems() {
    return async (dispatch) => {
        try {
            const response = await apiConnector('GET', GET_ALL_DSA_PROBLEMS);
            dispatch(setAllDSAProblems(response.data.allProblems));
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };
}

export function getSingleDSAProblem(problemId) {
    return async (dispatch) => {
        try {
            dispatch(setCurrentProblem({}));
            const response = await apiConnector('GET', GET_SINGLE_DSA_PROBLEMS + problemId);
            const problem = response.data.problem_data;
            dispatch(setCurrentProblem(problem));
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };
}

export function addDSAProblem(problemData, setModalOpen, reset) {
    return async (dispatch) => {
        try {
            const response = await apiConnector('POST', ADD_DSA_PROBLEM, problemData);
            toast.success(response.data.message);
            setModalOpen(false);
            reset({
                name: '',
                difficulty: 'easy',
                slug: '',
                hasIDE: false,
            });
            dispatch(getAllDSAProblems());
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };
}

export function updateDSAProblem(problem_data) {
    return async (dispatch) => {
        try {
            const response = await apiConnector('POST', UPDATE_DSA_PROBLEM, {problem_data});
            toast.success(response.data.message);
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };
}

export function deleteDSAProblem(problem_slug) {
    return async (dispatch) => {
        try {
            const response = await apiConnector('DELETE', DELETE_DSA_PROBLEM ,{slug: problem_slug});
            toast.success(response.data.message);
            dispatch(getAllDSAProblems());
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };
}

export function getAllTheoryProblems() {
    return async (dispatch) => {
        try {
            const response = await apiConnector('GET', GET_ALL_THEORY_PROBLEMS);
            dispatch(setAllTheoryProblems(response.data.allProblems || []));
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };
}

export function getSingleTheoryProblem(problemId) {
    return async (dispatch) => {
        try {
            dispatch(setCurrentProblem({}));

            const response = await apiConnector('GET', GET_SINGLE_THEORY_PROBLEMS + problemId);
            const problem = response.data.problem_data;
            dispatch(setCurrentProblem(problem));
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };
}

export function addTheoryProblem(problemData, setModalOpen, reset) {
    return async (dispatch) => {
        try {
            const response = await apiConnector('POST', ADD_THEORY_PROBLEM, problemData);
            toast.success(response.data.message);
            setModalOpen(false);
            reset({
                name: '',
                slug: '',
            });
            dispatch(getAllTheoryProblems());

        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };
}

export function updateTheoryProblem(problem_data) {
    return async (dispatch) => {
        try {
            const response = await apiConnector(
                'POST',
                UPDATE_THEORY_PROBLEM,
                {problem_data},
            );
            toast.success(response.data.message);
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };
}

export function deleteTheoryProblem(problem_slug) {
    return async (dispatch) => {
        try {
            const response = await apiConnector('DELETE', DELETE_THEORY_PROBLEM ,{slug:problem_slug});
            toast.success(response.data.message);
            dispatch(getAllTheoryProblems());

        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };
}

export function getAllQuizProblems() {
    return async (dispatch) => {
        try {
            const response = await apiConnector('GET', GET_ALL_QUIZ_PROBLEMS);
            dispatch(setAllQuizProblems(response.data.allProblems));
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };
}

export function getSingleQuizProblem(problemId) {
    return async (dispatch) => {
        try {
            dispatch(setCurrentProblem({}));

            const response = await apiConnector('GET', GET_SINGLE_QUIZ_PROBLEMS + problemId);
            const problem = response.data.problem_data;
            dispatch(setCurrentProblem(problem));
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };
}

export function addQuizProblem(problemData, setModalOpen, reset) {
    return async (dispatch) => {
        try {
            const response = await apiConnector('POST', ADD_QUIZ_PROBLEM, problemData);
            toast.success(response.data.message);
            setModalOpen(false);
            reset({
                name: '',
                slug: '',
            });
            dispatch(getAllQuizProblems());
            
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };
}

export function updateQuizProblem(problem_data) {
    return async (dispatch) => {
        try {
            const response = await apiConnector(
                'POST',
                UPDATE_QUIZ_PROBLEM ,
                {problem_data},
            );
            toast.success(response.data.message);
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };
}

export function deleteQuizProblem(problem_slug) {
    return async (dispatch) => {
        try {
            const response = await apiConnector('DELETE', DELETE_QUIZ_PROBLEM ,{slug: problem_slug});
            toast.success(response.data.message);
            dispatch(getAllQuizProblems());

        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };
}

export function getAllSubCategories_v2() {
    return async (dispatch) => {
        try {
            const response = await apiConnector('GET', GET_ALL_SUB_CATEGORIES_V2);
            dispatch(setAllSubCategories(response.data.allSubCategories));
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };
}

export function addSubCategory(name, slug, category, rank, type, setModalOpen) {
    return async (dispatch) => {
        try {
            const response = await apiConnector('POST', ADD_SUB_CATEGORY_V2, {
                name,
                slug,
                category,
                rank,
                type,
            });
            toast.success(response.data.message);
            setModalOpen(false);
            dispatch(getAllSubCategories_v2());

        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };
}

export function updateSubCategory(subCategoryId, name, slug, category, rank, type, setModalOpen) {
    return async (dispatch) => {
        try {
            const response = await apiConnector('POST', UPDATE_SUB_CATEGORY_V2 + subCategoryId, {
                name,
                slug,
                rank,
                category,
                type,
            });
            toast.success(response.data.message);
            setModalOpen(false);
            dispatch(getAllSubCategories_v2());

        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };
}

export function deleteSubCategory(subCategory_slug) {
    return async (dispatch) => {
        try {
            const response = await apiConnector('DELETE', DELETE_SUB_CATEGORY_V2 ,{slug: subCategory_slug});
            toast.success(response.data.message);
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };
}

export function getAllCategories_v2() {
    return async (dispatch) => {
        try {
            const response = await apiConnector('GET', GET_ALL_CATEGORIES_V2);
            dispatch(setAllCategories(response.data.allCategories));
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };
}

export function addCategory(name, slug, rank, subject, setModalOpen) {
    return async (dispatch) => {
        try {
            const response = await apiConnector('POST', ADD_CATEGORY_V2, {
                name,
                slug,
                rank,
                subject,
            });
            toast.success(response.data.message);
            setModalOpen(false);
            dispatch(getAllCategories_v2());
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };
}

export function updateCategory(categoryId, name, slug, rank, subject, setModalOpen) {
    return async (dispatch) => {
        try {
            const response = await apiConnector('POST', UPDATE_CATEGORY_V2 + categoryId, {
                name,
                slug,
                rank,
                subject,
            });
            toast.success(response.data.message);
            setModalOpen(false);
            dispatch(getAllCategories_v2());
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };
}

export function getAllTopics() {
    return async (dispatch) => {
        try {
            const response = await apiConnector('GET', GET_ALL_TOPICS_V2);
            dispatch(setAllSubjects(response.data.allTopics));
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };
}

export function addTopic(topicData, setModalOpen, reset) {
    return async (dispatch) => {
        try {
            const response = await apiConnector('POST', ADD_TOPIC_V2, topicData);
            toast.success(response.data.message);
            setModalOpen(false);
            reset({
                name: '',
                slug: '',
            });
            dispatch(getAllTopics());

        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };
}

export function updateTopic(topicData, setModalOpen) {
    return async (dispatch) => {
        try {
            const response = await apiConnector('POST', UPDATE_TOPIC_V2 + topicData.id, topicData);
            toast.success(response.data.message);
            setModalOpen(false);
            dispatch(getAllTopics());

        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };
}

export function getAllSubjectProblems(subject) {
    return async (dispatch) => {
        try {
            const response = await apiConnector('GET', GET_ALL_SUBJECT_PROBLEMS + subject);
            dispatch(setAllSubjectProblems(response.data.allProblems));
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };
}

export function setSubjectOrder(subject, category, subCategory, problems, type) {
    return async (dispatch) => {
        try {
            if (type === 'CATEGORY') {
                const response = await apiConnector(
                    'POST',
                    SUBJECT_ORDER.SET_CATEGORY_PROBLEM_ORDER + subject,
                    {
                        category,
                    },
                );
                toast.success(response.data.message);
            }
            if (type === 'SUBCATEGORY') {
                const response = await apiConnector(
                    'POST',
                    SUBJECT_ORDER.SET_SUB_CATEGORY_PROBLEM_ORDER + subject,
                    {
                        subCategory,
                    },
                );
                toast.success(response.data.message);
            }
            if (type === 'PROBLEM') {
                const response = await apiConnector('POST', 
                    SUBJECT_ORDER.SET_PROBLEM_ORDER + subject, {
                    category,
                    subCategory,
                    problems,
                });
                toast.success(response.data.message);
            }
            dispatch(getAllSubjectProblems(subject));
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };
}

export function deleteCategory(category_slug) {
    return async (dispatch) => {
        try {
            const response = await apiConnector('DELETE', DELETE_CATEGORY_V2 ,{slug: category_slug});
            toast.success(response.data.message);
            dispatch(getAllCategories_v2());
        } catch (error) {
            console.log(error); 
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };
}

export function deleteTopic(subject_slug){
    return  async (dispatch) => {
        try {
            const response = await apiConnector('DELETE', DELETE_TOPIC_V2 ,{slug: subject_slug});
            toast.success(response.data.message);
            dispatch(getAllTopics());
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    }
}