import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import Accordion from '../../../components/Accordion/Accordion';
import { BadgeX } from 'lucide-react';
import { updateDSAProblem } from '../../../services/operations/new-syllabus';

const EditorialEdit = (props) => {
    const { CurrentProblem } = useSelector((state) => state.new_syllabus);
    const dispatch = useDispatch();

    const {
        handleSubmit,
        reset,
        control,
        formState: { errors },
    } = useForm({
        defaultValues: {
            editorial: CurrentProblem?.editorial || {},
        },
    });

    const [editorials, setEditorials] = useState([]);

    useEffect(() => {
        if (CurrentProblem?.editorial) {
            const editorialEntries = Object.entries(CurrentProblem.editorial);
            console.log(editorialEntries);
            setEditorials(editorialEntries);
            reset({ editorial: CurrentProblem.editorial });
        }
    }, [CurrentProblem, reset]);

    const handleKeyChange = (index, newKey) => {
        const updatedEditorials = [...editorials];
        updatedEditorials[index][0] = newKey;
        setEditorials(updatedEditorials);
    };

    const handleValueChange = (index, newValue) => {
        const updatedEditorials = [...editorials];
        updatedEditorials[index][1] = newValue;
        setEditorials(updatedEditorials);
    };

    const onSubmit = (data) => {
        const editorialData = Object.fromEntries(editorials);

        const problem_data = {
            type:"editorial",
            id: CurrentProblem.id,
            slug: CurrentProblem.slug,
            editorial: editorialData,
        };

        dispatch(updateDSAProblem(problem_data));
    };

    return (
        <div className='p-4'>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='flex flex-col gap-4'>
                    {editorials.length > 0 ? (
                        editorials.map(([key, value], index) => (
                            <div key={index} className='flex items-center gap-2'>
                                <div className='w-full'>
                                    <Accordion key={index} title={key}>
                                        <div className='flex items-center gap-x-4 mb-2'>
                                            <input
                                                type='text'
                                                value={key}
                                                onChange={(e) =>
                                                    handleKeyChange(index, e.target.value)
                                                }
                                                className='form-input w-1/3 text-gray-800 border rounded p-2'
                                                placeholder='Editorial Key'
                                            />
                                        </div>

                                        <div className='flex items-center gap-x-4 mb-2'>
                                            <Controller
                                                name={`editorial.${key}`}
                                                control={control}
                                                render={({ field }) => (
                                                    <textarea
                                                        value={value}
                                                        onChange={(e) =>
                                                            handleValueChange(index, e.target.value)
                                                        }
                                                        className='form-input w-full text-gray-800 border rounded p-2 h-80'
                                                        placeholder='Editorial Value'
                                                    />
                                                )}
                                            />
                                        </div>

                                        {errors?.editorial?.[key] && (
                                            <span className='text-red-500'>
                                                {errors.editorial[key]?.message}
                                            </span>
                                        )}
                                    </Accordion>
                                </div>
                                <BadgeX
                                    onClick={() => {
                                        const updatedEditorials = editorials.filter(
                                            (_, i) => i !== index,
                                        );
                                        setEditorials(updatedEditorials);
                                    }}
                                    className='cursor-pointer text-red-500'
                                />
                            </div>
                        ))
                    ) : (
                        <div className='flex items-center justify-center text-gray-500'>
                            No editorials added yet.
                        </div>
                    )}
                    <button
                        type='button'
                        className='p-2 rounded bg-blue-500 text-white w-32'
                        onClick={() => {
                            setEditorials([...editorials, ['Enter name', '']]);
                        }}
                    >
                        Add Editorial
                    </button>
                    <div className='mt-4 flex gap-10'>
                        <button type='submit' className={`p-2 rounded bg-blue-500 text-white w-32`}>
                            Save
                        </button>
                        <button
                            type='button'
                            className={`p-2 rounded bg-blue-500  text-white w-32`}
                            onClick={() => {
                                const editorialData = Object.fromEntries(editorials);
                                localStorage.setItem(
                                    'editorialData',
                                    JSON.stringify(editorialData),
                                );
                                window.open('/manage-content/problem/preview', '_blank');
                            }}
                        >
                            Preview
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default EditorialEdit;
