import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    // Doubt stats
    activeUsers: [],
    totalDoubts: [],
    uniqueUsers: [],
    costByDay: [],
    totalCost: 0,
    doubtLoading: false,
    doubtError: null,

    // Submission stats
    dailyMetrics: [],
    languageWiseSubmissions: [],
    submissionLoading: false,
    submissionError: null,

    // total and active users summary
    activeUsersSummary: [],
    totalUsers: 0,
    activeUsersToday: 0,
    summaryLoading: false,
    summaryError: null,

    // progress percentage
    activeUsersProgressSummary: [], 
    progressSummaryLoading: false,
    progressSummaryError: null,

    //user-feedback
    averageRating: 0,
    ratingDistribution: [],
    oneRated: { data: [], totalRatings: 0, currentPage: 1 },
    twoRated: { data: [], totalRatings: 0, currentPage: 1 },
    threeRated: { data: [], totalRatings: 0, currentPage: 1 },
    fourRated: { data: [], totalRatings: 0, currentPage: 1 },
    fiveRated: { data: [], totalRatings: 0, currentPage: 1 },
};

const userStatsSlice = createSlice({
    name: "userStats",
    initialState: initialState,
    reducers: {
        setActiveUsers(state, action) {
            state.activeUsers = action.payload;
        },
        setTotalDoubts(state, action) {
            state.totalDoubts = action.payload;
        },
        setUniqueUsers(state, action) {
            state.uniqueUsers = action.payload;
        },
        setCostByDay(state, action) {
            state.costByDay = action.payload;
        },
        setTotalCost(state, action) {
            state.totalCost = action.payload;
        },
        setDoubtStatsLoading(state, action) {
            state.doubtLoading = action.payload;
        },
        setDoubtStatsError(state, action) {
            state.doubtError = action.payload;
        },
        setDailyMetrics(state, action) {
            state.dailyMetrics = action.payload;
        },
        setLanguageWiseSubmissions(state, action) {
            state.languageWiseSubmissions = action.payload;
        },
        setSubmissionStatsLoading(state, action) {
            state.submissionLoading = action.payload;
        },
        setSubmissionStatsError(state, action) {
            state.submissionError = action.payload;
        },
        setActiveUsersSummary(state, action) {
            state.activeUsersSummary = action.payload;
        },
        setTotalUsers(state, action) {
            state.totalUsers = action.payload;
        },
        setActiveUsersToday(state, action) {
            state.activeUsersToday = action.payload;
        },
        setSummaryLoading(state, action) {
            state.summaryLoading = action.payload;
        },
        setSummaryError(state, action) {
            state.summaryError = action.payload;
        },
        setActiveUsersProgressSummary: (state, action) => {
            state.activeUsersProgressSummary = action.payload;
        },
        setProgressSummaryLoading: (state, action) => {
            state.progressSummaryLoading = action.payload;
        },
        setProgressSummaryError: (state, action) => {
            state.progressSummaryError = action.payload;
        },
        setAverageFeedback: (state, action) => {
            state.averageRating = action.payload;
        },
        setRatingDistribution: (state, action) => {
            state.ratingDistribution = action.payload;
        },
        setOneRatedFeedback: (state, action) => {
            state.oneRated.data = action.payload.recentRatings;
            state.oneRated.totalRatings = action.payload.totalRatings;
            state.oneRated.currentPage = action.payload.currentPage;
        },
        setTwoRatedFeedback: (state, action) => {
            state.twoRated.data = action.payload.recentRatings;
            state.twoRated.totalRatings = action.payload.totalRatings;
            state.twoRated.currentPage = action.payload.currentPage;
        },
        setThreeRatedFeedback: (state, action) => {
            state.threeRated.data = action.payload.recentRatings;
            state.threeRated.totalRatings = action.payload.totalRatings;
            state.threeRated.currentPage = action.payload.currentPage;
        },
        setFourRatedFeedback: (state, action) => {
            state.fourRated.data = action.payload.recentRatings;
            state.fourRated.totalRatings = action.payload.totalRatings;
            state.fourRated.currentPage = action.payload.currentPage;
        },
        setFiveRatedFeedback: (state, action) => {
            state.fiveRated.data = action.payload.recentRatings;
            state.fiveRated.totalRatings = action.payload.totalRatings;
            state.fiveRated.currentPage = action.payload.currentPage;
        },
        clearRatingsData: (state, action) => {
            const rating = action.payload;
            if (rating === 1) state.oneRated = initialState.oneRated;
            if (rating === 2) state.twoRated = initialState.twoRated;
            if (rating === 3) state.threeRated = initialState.threeRated;
            if (rating === 4) state.fourRated = initialState.fourRated;
            if (rating === 5) state.fiveRated = initialState.fiveRated;
        }
    },
});

export const { setActiveUsers, setTotalDoubts, setUniqueUsers, setCostByDay, setTotalCost, setDoubtStatsLoading, setDoubtStatsError, setDailyMetrics, setLanguageWiseSubmissions, setSubmissionStatsLoading, setSubmissionStatsError, setActiveUsersSummary, setTotalUsers, setActiveUsersToday, setSummaryLoading, setSummaryError, setActiveUsersProgressSummary, setProgressSummaryLoading, setProgressSummaryError, setAverageFeedback, setRatingDistribution, setOneRatedFeedback, setTwoRatedFeedback, setThreeRatedFeedback, setFourRatedFeedback, setFiveRatedFeedback, clearRatingsData } = userStatsSlice.actions;
export default userStatsSlice.reducer;