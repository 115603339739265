import React, { useState } from 'react';

const Tabs = ({ children }) => {
    const [activeTab, setActiveTab] = useState(children[0].props.label);

    const onClickTab = (tab) => {
        setActiveTab(tab);
    };

    return (
        <div className="w-full">
            <div className="flex border-b border-gray-200 dark:border-gray-700">
                {children.map((child) => {
                    const { label } = child.props;
                    return (
                        <button
                            key={label}
                            className={`py-2 px-4 -mb-px font-semibold text-md focus:outline-none ${
                                activeTab === label
                                    ? 'border-b-2 border-blue-500 text-blue-600 dark:text-blue-400'
                                    : 'text-gray-600 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400'
                            }`}
                            onClick={() => onClickTab(label)}
                        >
                            {label}
                        </button>
                    );
                })}
            </div>
            <div className="mt-8">
                {children.map((child) => {
                    if (child.props.label !== activeTab) return undefined;
                    return child.props.children;
                })}
            </div>
        </div>
    );
};

export default Tabs;

