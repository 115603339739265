import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    AllDSAProblems: [],
    AllTheoryProblems:[],
    AllQuizProblems: [],
    CurrentProblem:{},

    AllSubjects: [],
    Subject:{},
    AllCategories: [],
    Category:{},
    AllSubCategories: [],
    SubCategory:{},

    AllSubjectProblems: [],
};

const newSyllabusSlice = createSlice({
    name: "newSyllabus",
    initialState: initialState,
    reducers: {
        setAllDSAProblems(state, value) {
            state.AllDSAProblems = value.payload;
        },
        setCurrentProblem(state, value) {
            state.CurrentProblem = value.payload;
        },
        setAllTheoryProblems(state, value) {
            state.AllTheoryProblems = value.payload;
        },
        setAllQuizProblems(state, value) {
            state.AllQuizProblems = value.payload;
        },
        setAllSubjects(state, value) {
            state.AllSubjects = value.payload;
        },
        setSubject(state, value) {
            state.Subject = value.payload;
        },
        setAllCategories(state, value) {
            state.AllCategories = value.payload;
        },
        setAllSubCategories(state, value) {
            state.AllSubCategories = value.payload;
        },
        setCategory(state, value) {
            state.Category = value.payload;
        },
        setSubCategory(state, value) {
            state.SubCategory = value.payload;
        },
        setAllSubjectProblems(state, value) {
            state.AllSubjectProblems = value.payload;
        }
    },
});

export const { setAllDSAProblems, setCurrentProblem, setAllTheoryProblems, setAllQuizProblems, setAllSubjects, setAllCategories, setAllSubCategories, setSubject, setCategory, setSubCategory, setAllSubjectProblems } = newSyllabusSlice.actions;
export default newSyllabusSlice.reducer;