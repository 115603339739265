import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    pending: { data: [], currentPage: 1 },
    approved: { data: [], currentPage: 1 },
    disapproved: { data: [], currentPage: 1 },
};

const storiesSlice = createSlice({
    name: "stories",
    initialState: initialState,
    reducers: {
        setPendingStories(state, action) {
            state.pending = {
                data: action.payload.pendingStories || [],
                currentPage: action.payload.page || 1,
            };
        },
        setApprovedStories(state, action) {
            state.approved = {
                data: action.payload.approvedStories || [],
                currentPage: action.payload.page || 1,
            };
        },        
        setDisapprovedStories(state, action) {
            state.disapproved = {
                data: action.payload.disapprovedStories || [],
                currentPage: action.payload.page || 1,
            };
        },
    },
});

export const { setPendingStories, setApprovedStories, setDisapprovedStories } = storiesSlice.actions;
export default storiesSlice.reducer;