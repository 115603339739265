/* eslint-disable no-unused-vars */
import { BadgeX, Plus, XCircle } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Accordion from '../../../components/Accordion/Accordion';
import Header from '../../../components/Header/Header';
import RichEditor from '../../../components/RichEditor/RichEditor';
import Sidebar from '../../../components/Sidebar/Sidebar';
import {
  clearCacheJudge,
  getProblemDetails,
  saveProblemSetter,
} from '../../../services/operations/syllabus';
import { setProblemSetter } from '../../../slices/syllabusSlice';
import { encoderFunction } from './encoderFunction';
import toast from 'react-hot-toast';
import { generateDriverCode } from './generateDriverCode.mjs';

const ProblemSetterForm = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [problemNote, setProblemNote] = useState(null);
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = params;

  const { problemSetter } = useSelector((state) => state.syllabus);
  const { isProd } = useSelector((state) => state.auth);
  // eslint-disable-next-line no-unused-vars
  const [inputOutputPairs, setInputOutputPairs] = useState([]);
  const [additionalFields, setAdditionalFields] = useState(0);
  const [edgeCasesValueArray, setEdgeCasesValueArray] = useState([]);
  const [additionalOptions, setAdditionalOptions] = useState([]);
  const [problemIOData, setProblemIOData] = useState({});
  const [output, setOutput] = useState([
    {
      input: 'Encoded Input Value',
    },
  ]);
  const [encoderError, setEncoderError] = useState(null);
  const [isEncoderFunctionRun, setIsEncoderFunctionRun] = useState(false);

  const addOptions = () => {
    setAdditionalOptions([...additionalOptions, { id: Date.now() }]);
  };

  const removeOptions = (id) => {
    setAdditionalOptions(additionalOptions.filter((option) => option.id !== id));
    // Reset the value of the last option
    setValue(`option${additionalOptions.length + 1}`, '');
  };

  useEffect(() => {
    const decodedId = encodeURIComponent(id);
    dispatch(getProblemDetails(decodedId));
  }, [dispatch, id]);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    watch,
    control,
    formState: { errors, isDirty, isSubmitted },
  } = useForm({
    defaultValues: problemSetter,
  });

  useEffect(() => {
    if (problemSetter && problemSetter?.gamificationOptions) {
      setAdditionalOptions(
        problemSetter?.gamificationOptions?.map((option, index) => {
          return { id: index, value: option };
        }),
      );
    }
    // console.log(problemSetter);
  }, [problemSetter]);

  useEffect(() => {
    reset({
      ...problemSetter,
      bigInput: problemSetter.bigInput || '',
      bigOutput: problemSetter.bigOutput || '',
      bigInputTle: problemSetter.bigInputTle || '',
      bigOutputTle: problemSetter.bigOutputTle || '',
    });
  }, [problemSetter, reset]);

  const [showExample3, setShowExample3] = useState(false);

  useEffect(() => {
    const data = problemSetter?.hasGamification === 1 ? true : false;
    setShowExample3(data);
  }, [problemSetter?.hasGamification]);

  const onEditorContentChanged = ({ name, html }) => {
    setValue(name, html);
  };
  const addTopic = () => {
    const topics = watch('topics') || [];
    const newTopic = watch('newTopic');

    if (newTopic && !topics.includes(newTopic)) {
      setValue('topics', [...topics, newTopic]);
      setValue('newTopic', '');
    }
  };

  const removeTopic = (index) => {
    const topics = watch('topics') || [];
    const updatedTopics = [...topics.slice(0, index), ...topics.slice(index + 1)];
    setValue('topics', updatedTopics);
  };
  useEffect(() => {
    const jsonString = problemSetter?.edgeCases;

    if (jsonString) {
      try {
        const parsedArray = jsonString;
        if (Array.isArray(parsedArray)) {
          setEdgeCasesValueArray(parsedArray);
          setAdditionalFields(parsedArray.length);
        }
      } catch (error) {
        // console.error('Error parsing JSON:', error);
      }
    }
  }, [problemSetter?.edgeCases]);

  const handleOnSubmit = async (data) => {
    try {
        // Check if the all the required fields are filled and the encoder function has been run
        if (!isEncoderFunctionRun) {
          throw new Error(
            'Please fill the required fields of section 3 and run the encoder function',
          );
        }

        // Updates problemIOData with form data, runs encoderFunction, and updates problem_desc.
        await handleSubmitIO(onSubmitIO)();

        if (encoderError) {
          throw new Error('Error in encoder function');
        }
      

      const edgeCases = [];

      for (let i = 1; i <= additionalFields; i++) {
        const newObject = {
          input: data[`realInput${i + 3}`],
          output: data[`realOutput${i + 3}`],
        };

        edgeCases.push(newObject);
      }

      const optionCases = [];
      for (let j = 1; j <= additionalOptions.length; j++) {
        const output = data[`option${j}`];
        optionCases.push(output);
      }

      const problem_desc = {
        id: id,
        problem_name: data.problem_name,
        problem_statement: data.problem_statement,
        example1: data.example1,
        example2: data.example2,
        constraints: data.constraints,
        notes: data.notes,
        showInput1: data.showInput1,
        showInput2: data.showInput2,
        realInput1: data.realInput1,
        realInput2: data.realInput2,
        realOutput1: data.realOutput1,
        realOutput2: data.realOutput2,
        publicCpp: data.publicCpp,
        privateCpp: data.privateCpp,
        beforePublicCpp: data.beforePublicCpp,
        publicJava: data.publicJava,
        privateJava: data.privateJava,
        beforePublicJava: data.beforePublicJava,
        publicPy: data.publicPy,
        privatePy: data.privatePy,
        beforePublicPy: data.beforePublicPy,
        publicJs: data.publicJs,
        privateJs: data.privateJs,
        beforePublicJs: data.beforePublicJs,
        cppTimeLimit: data.cppTimeLimit,
        correctCpp: data.correctCpp,
        correctJava: data.correctJava,
        correctPy: data.correctPy,
        correctJs: data.correctJs,
        correctCodeLang: data.correctCodeLang,
        correctCode: data.correctCode,
        bigInput: data.bigInput,
        bigOutput: data.bigOutput,
        edgeCases: edgeCases,
        bigInputTle: data.bigInputTle,
        bigOutputTle: data.bigOutputTle,
        customTC: data.customTC,
        hasGamification: showExample3 ? 1 : 0,
        facts: data.facts,
        gamificationOptions: optionCases,
        correctGamificationOptionNumber: data.correctGamificationOptionNumber
          ? data.correctGamificationOptionNumber
          : 1,
        inputAndOutputStructure: problemIOData.inputAndOutputStructure,
        testcases: problemIOData.testcases,
        problemLogicalData: problemIOData.problemLogicalData,
        // cppTimeLimit: problemIOData.cppTimeLimit,  // This is already present in the form data: will uncomment this when we move to section 3 completely
        hiddenTestCases: problemIOData.hiddenTestCases,
      };
      if (showExample3) {
        problem_desc.example3 = data.example3;
        problem_desc.gamification_ouput = data.gamification_ouput;
      }
      dispatch(saveProblemSetter(id, problem_desc, isProd));
    } catch (error) {
      toast.error(error.message, {
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
        },
      });
    }
  };

  const handleSaveReview = () => {
    // dispatch(saveReview(id, navigate))
  };

  const isProblemValid = () => {  
    const topics = watch('topics') || [];

    for (const key in problemSetter) {
      if (
        key !== 'id' &&
        key !== 'problem_name' &&
        (problemSetter[key] === null || problemSetter[key] === '')
      ) {
        return false;
      }
    }

    // Check if the length of the topics array has changed
    if (topics?.length !== problemSetter?.topics?.length) {
      return false;
    }

    return true;
  };

  const handleAddFields = () => {
    setAdditionalFields((prevFields) => {
      const currentFields = prevFields + 1;

      // console.log(currentFields);
      // Create a new object with the current input and output values
      const newPair = {
        input: watch(`realInput${currentFields}`),
        output: watch(`realOutput${currentFields}`),
      };

      // Add the new object to the array
      setInputOutputPairs((prevPairs) => [...prevPairs, newPair]);

      // Return the updated value for additionalFields
      return currentFields;
    });
  };

  const handleRemoveFields = () => {
    setAdditionalFields((prevFields) => prevFields - 1);

    // Remove the last object from the array
    setInputOutputPairs((prevPairs) => prevPairs.slice(0, -1));
  };
  // console.log(problemSetter)

  const handlePreview = (e) => {
    e.preventDefault();

    const edgeCases = [];

    for (let i = 1; i <= additionalFields; i++) {
      const newObject = {
        input: getValues(`realInput${i + 3}`),
        output: getValues(`realOutput${i + 3}`),
      };

      edgeCases.push(newObject);
    }
    const problem_desc = {
      id: id,
      problem_name: getValues('problem_name'),
      problem_statement: getValues('problem_statement'),
      example1: getValues('example1'),
      example2: getValues('example2'),
      constraints: getValues('constraints'),
      notes: getValues('notes'),
      showInput1: getValues('showInput1'),
      showInput2: getValues('showInput2'),
      realInput1: getValues('realInput1'),
      realInput2: getValues('realInput2'),
      realOutput1: getValues('realOutput1'),
      realOutput2: getValues('realOutput2'),
      publicCpp: getValues('publicCpp'),
      privateCpp: getValues('privateCpp'),
      publicJava: getValues('publicJava'),
      privateJava: getValues('privateJava'),
      publicPy: getValues('publicPy'),
      privatePy: getValues('privatePy'),
      publicJs: getValues('publicJs'),
      privateJs: getValues('privateJs'),
      cppTimeLimit: getValues('cppTimeLimit'),
      correctCpp: getValues('correctCpp'),
      correctJava: getValues('correctJava'),
      correctPy: getValues('correctPy'),
      correctJs: getValues('correctJs'),
      correctCodeLang: getValues('correctCodeLang'),
      correctCode: getValues('correctCode'),
      bigInput: getValues('bigInput'),
      bigOutput: getValues('bigOutput'),
      edgeCases: edgeCases,
      bigInputTle: getValues('bigInputTle'),
      bigOutputTle: getValues('bigOutputTle'),
      customTC: getValues('customTC'),
      hasGamification: showExample3 ? 1 : 0,
      facts: getValues('facts'),
    };

    if (showExample3) {
      problem_desc.example3 = getValues('example3');
      problem_desc.gamification_ouput = getValues('gamification_ouput');
    }

    if (localStorage.getItem(`${id}`, JSON.stringify(problem_desc))) {
      localStorage.removeItem(`${id}`);
    }
    localStorage.setItem(`${id}`, JSON.stringify(problem_desc));
    const url = `/syllabus-content/problem-setter/${encodeURIComponent(id)}/preview`;
    window.open(url, '_blank');
  };
  useEffect(() => {
    const decodedId = encodeURIComponent(id);
    if (decodedId) {
      setProblemNote(problemSetter?.note);
    } else {
      setProblemNote(null);
    }
  }, [problemSetter, id]);

  // Section 3 Form
  const {
    control: controlIO,
    register: registerIO,
    watch: watchIO,
    handleSubmit: handleSubmitIO,
    setValue: setValueIO,
    reset: resetIO,
    formState: { errors: errorsIO },
  } = useForm({
    defaultValues: {
      input_structure: [{ label: 'nums', type: 'array', subtype: 'integer' }],
      output_structure: { type: 'integer' },
      testcases: [
        { inputs: '', parsedOutput: '', parsedInput: '' },
        { inputs: '', parsedOutput: '', parsedInput: '' },
        { inputs: '', parsedOutput: '', parsedInput: '' },
      ],
      problemLogicalData: {
        cpp: {
          headerCode: '',
          showCodeOnIDE: '',
          driverCode: '',
        },
        java: {
          headerCode: '',
          showCodeOnIDE: '',
          driverCode: '',
        },
        python: {
          headerCode: '',
          showCodeOnIDE: '',
          driverCode: '',
        },
        javascript: {
          headerCode: '',
          showCodeOnIDE: '',
          driverCode: '',
        },
        correctCodeAndLanguage: {
          correctCodeLanguage: 'cpp',
          correctCode: '',
        },
        testCaseGeneratorCode: '',
      },
      cppTimeLimit: 1,
      hiddenTestCases: {
        largeInputFileWA: '',
        largeOutputFileWA: '',
        largeInputFileTLE: '',
        largeOutputFileTLE: '',
      },
    },
  });

  // When problemSetter is available, the useEffect hook will trigger, and the reset function will update the form values with the data from problemSetter
  useEffect(() => {
    if (problemSetter) {
      resetIO({
        input_structure: problemSetter.inputAndOutputStructure?.input_structure || [
          { label: 'nums', type: 'array', subtype: 'integer' },
        ],
        output_structure: problemSetter.inputAndOutputStructure?.output_structure || {
          type: 'integer',
        },
        testcases: problemSetter.testcases || [
          { inputs: '', parsedOutput: '', parsedInput: '' },
          { inputs: '', parsedOutput: '', parsedInput: '' },
          { inputs: '', parsedOutput: '', parsedInput: '' },
        ],
        problemLogicalData: problemSetter.problemLogicalData || {
          cpp: {
            headerCode: problemSetter?.beforePublicCpp || '',
            showCodeOnIDE: problemSetter?.publicCpp || '',
            driverCode: '',
          },
          java: {
            headerCode: problemSetter?.beforePublicJava || '',
            showCodeOnIDE: problemSetter?.publicJava || '',
            driverCode: '',
          },
          python: {
            headerCode: problemSetter?.beforePublicPy || '',
            showCodeOnIDE: problemSetter?.publicPy || '',
            driverCode: '',
          },
          javascript: {
            headerCode: problemSetter?.beforePublicJs || '',
            showCodeOnIDE: problemSetter?.publicJs || '',
            driverCode: '',
          },
          correctCodeAndLanguage: {
            correctCodeLanguage: problemSetter?.correctCodeLang || 'cpp',
            correctCode: problemSetter?.correctCode || '',
          },
          testCaseGeneratorCode: problemSetter?.testCodeGenerator || '',
        },
        cppTimeLimit: problemSetter.cppTimeLimit || 1,
        hiddenTestCases: problemSetter.hiddenTestCases || {
          largeInputFileWA: '',
          largeOutputFileWA: '',
          largeInputFileTLE: '',
          largeOutputFileTLE: '',
        },
      });
    }
  }, [problemSetter, resetIO]);

  // const generateCode = async () => {
  //   try {
  //     const response = await generateDriverCode(problemSetter?.privateCpp, problemSetter?.privateJava, problemSetter?.privatePy, problemSetter?.privateJs);
  //     console.log(response);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // useEffect(() => {
  //     // console.log(problemSetter)
  //     if(problemSetter?.problemLogicalData == null){

  //        if(problemSetter?.privateCpp && problemSetter?.privateJava && problemSetter?.privatePy && problemSetter?.privateJs){
  //        console.log(problemSetter?.privateCpp)
  //         generateCode(problemSetter?.privateCpp, problemSetter?.privateJava, problemSetter?.privatePy, problemSetter?.privateJs).then((response) => console.log(response)); ;
  //        }
  //     }
  // }, [problemSetter?.privateCpp]);

  const driverCodeGeneratoFunction = async () => {
    try {
      const response = await generateDriverCode(
        problemSetter?.privateCpp,
        problemSetter?.privateJava,
        problemSetter?.privatePy,
        problemSetter?.privateJs,
        problemSetter?.correctCode,
      );
      // console.log(response);
      const { cppDriverCode, javaDriverCode, pythonDriverCode, javascriptDriverCode, correctCode } =
        response;

      // Update the problemLogicalData with the driver codes
      setValueIO('problemLogicalData', {
        cpp: { ...watchIO('problemLogicalData').cpp, driverCode: cppDriverCode },
        java: { ...watchIO('problemLogicalData').java, driverCode: javaDriverCode },
        python: { ...watchIO('problemLogicalData').python, driverCode: pythonDriverCode },
        javascript: {
          ...watchIO('problemLogicalData').javascript,
          driverCode: javascriptDriverCode,
        },
        correctCodeAndLanguage: {
          correctCodeLanguage: problemSetter?.correctCodeLang || 'cpp',
          correctCode: correctCode || '',
        },
      });
      return null;
    } catch (error) {
      toast.error(error.message || 'Error generating driver code');
    }
  };

  const generateDriverCodeHandler = async (e) => {
    try {
      e.preventDefault();
      // Commented out the below code because some driver code are not generated 

      // if (problemSetter?.problemLogicalData?.cpp?.driverCode) {
      //   toast('Driver code already generated', {
      //     icon: '✅',
      //   });
      //   return;
      // }
      if (
        problemSetter?.privateCpp &&
        problemSetter?.privateJava &&
        problemSetter?.privatePy &&
        problemSetter?.privateJs
      ) {
        const response = driverCodeGeneratoFunction();
        toast.promise(response, {
          loading: 'Generating driver code...',
          success: 'Driver code generated successfully',
          error: 'Error generating driver code',
        });
        return;
      }
      toast('Please put driver code manually', {
        icon: '🛠️',
      });
    } catch (error) {
      toast.error(error.message || 'Error generating driver code');
    }
  };

  // Add a new input structure field
  const handleLabelChange = (index, newLabel) => {
    const inputStructure = [...watchIO('input_structure')];
    inputStructure[index].label = newLabel;
    setValueIO('input_structure', inputStructure);

    // Update testcases inputs to reflect new labels
    const testcases = watchIO('testcases').map((testcase) => {
      const newInputs = {};
      inputStructure.forEach((input) => {
        newInputs[input.label] = testcase.inputs[input.label] || '';
      });
      return { ...testcase, inputs: newInputs };
    });
    setValueIO('testcases', testcases);
  };

  // Remove a label from the input structure
  // TODO: Currently, clicking on a specific label removes the last label instead of the selected one. This needs to be fixed.
  const removeLabel = (index) => {
    const inputStructure = watchIO('input_structure');
    const testcases = watchIO('testcases');
    const removedLabel = inputStructure[index].label;

    const updatedInputStructure = inputStructure.filter((_, i) => i !== index);
    setValueIO('input_structure', updatedInputStructure);

    const updatedTestcases = testcases.map((testcase) => {
      const newInputs = { ...testcase.inputs };
      delete newInputs[removedLabel];
      return { ...testcase, inputs: newInputs };
    });
    setValueIO('testcases', updatedTestcases);
  };

  /*
   * Function to handle the submission of the form:
   * This handleOnSubmit function manages the submission for both Section 1 and Section 2, which are part of the same form.
   * Since Section 3 is in a different form, submitting Sections 1 and 2 also triggers the submission of Section 3.
   * This is achieved by calling the onSubmitIO function within handleOnSubmit, ensuring all sections are submitted together.
   */

  // Later runEncoderFunction will be used to run the encoder function only
  // const runEncoderFunction = ()=>{
  //     try {
  //         setEncoderError(null);
  //         const response = encoderFunction(data);
  //         setOutput(response);
  //         setIsEncoderFunctionRun(true);
  //         toast.success('Encoder Function ran successfully')

  //     } catch (error) {
  //         setEncoderError(error.message);
  //         toast.error(error.message, {
  //             style: {
  //                 borderRadius: '10px',
  //                 background: '#333',
  //                 color: '#fff',
  //               },
  //         })
  //     }
  // }

  const onSubmitIO = async (data) => {
    try {
      // console.log(data);
      setEncoderError(null);
      // Run the encoder function with the submitted data
      // console.log(data);
      const response = encoderFunction(data);
      setOutput(response);
      setValueIO('testcases', watchIO('testcases').map((testcase, index) => {
        return {
          ...testcase,
          parsedInput: response[index].input,
        };
      }));

      // Set problemIOData with the submitted data
      const problemIODataPayload = {
        inputAndOutputStructure: {
          input_structure: data.input_structure,
          output_structure: data.output_structure,
        },
        testcases: data.testcases,
        problemLogicalData: data.problemLogicalData,
        cppTimeLimit: data.cppTimeLimit,
        hiddenTestCases: data.hiddenTestCases,
      };
      setProblemIOData((prevData) => ({ ...prevData, ...problemIODataPayload }));
      setIsEncoderFunctionRun(true);
      toast.success('Section 3 save successfully, Now you can submit the form');
    } catch (error) {
      setEncoderError(error.message);
      toast.error(error.message, {
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
        },
      });
    }
  };

  // Watching for changes in problemLogicalData
  const problemLogicalData = watchIO('problemLogicalData');

  return (
    <div className='flex  dark:bg-dark_50  h-screen overflow-hidden'>
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className='relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden'>
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className='px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto'>
            <div className='mt-4 mb-10'>
              <div className='flex flex-col flex-wrap -mx-3 mb-4'>
                <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>
                  Note
                </span>
                <div className='w-full px-3'>{problemNote && problemNote}</div>
              </div>
            </div>

            <div className=' mb-8'>
              <div className='flex-grow font-primary'>
                <section className='p-4'>
                  <form className='flex flex-col mt-6 gap-y-6'>
                    <div className='flex flex-col gap-y-2'>
                      <span className='text-gray-800 dark:text-zinc-300 text-[16px] font-medium'>
                        Problem Name<span className='text-red-500'>*</span>
                      </span>
                      {errors?.problem_name && (
                        <span className='text-red-500'>{errors?.problem_name.message}</span>
                      )}
                      <Controller
                        name='problem_name'
                        control={control}
                        defaultValue={problemSetter?.problem_name || ''}
                        rules={{ required: 'Problem Name is required' }}
                        render={({ field, fieldState }) => (
                          <div>
                            <input
                              type='text'
                              name='problem_name'
                              disabled={true}
                              className='form-input w-full text-gray-300'
                              placeholder='Problem Name'
                              {...field}
                            />
                            {fieldState?.error && (
                              <span className='text-red-500'>{fieldState.error.message}</span>
                            )}
                          </div>
                        )}
                      />
                    </div>

                    <div className='mt-6'>
                      <Accordion title='Section - 1' errors={errors}>
                        <div className='flex flex-col gap-y-6'>
                          <div className='flex flex-col gap-y-2 mt-2'>
                            <div className='flex items-center gap-x-4'>
                              <span className='text-gray-800 dark:text-zinc-300 text-[16px] font-medium'>
                                Problem Statement <span className='text-red-500'>*</span>
                              </span>
                              {errors.problem_statement && (
                                <span className='text-red-500'>
                                  Please enter at least 10 characters.
                                </span>
                              )}
                            </div>
                            <RichEditor
                              name='problem_statement'
                              value={problemSetter?.problem_statement || ''}
                              options='Discussion'
                              {...register('problem_statement')}
                              onChange={onEditorContentChanged}
                              height='rich'
                            />
                          </div>
                          <div className='flex flex-col gap-y-2 mt-16'>
                            <div className='flex items-center gap-x-4'>
                              <span className='text-gray-800 dark:text-zinc-300 text-[16px] font-medium'>
                                Example 1 <span className='text-red-500'>*</span>
                              </span>
                              {errors.example1 && (
                                <span className='text-red-500'>
                                  Please enter at least 10 characters.
                                </span>
                              )}
                            </div>
                            <RichEditor
                              name='example1'
                              value={problemSetter?.example1 || ''}
                              options='Draft'
                              {...register('example1')}
                              onChange={onEditorContentChanged}
                              height='rich'
                            />
                          </div>
                          <div className='flex flex-col gap-y-2 mt-16'>
                            <div className='flex items-center gap-x-4'>
                              <span className='text-gray-800 dark:text-zinc-300 text-[16px] font-medium'>
                                Example 2 <span className='text-red-500'>*</span>
                              </span>
                              {errors.example2 && (
                                <span className='text-red-500'>
                                  Please enter at least 10 characters.
                                </span>
                              )}
                            </div>
                            <RichEditor
                              name='example2'
                              value={problemSetter?.example2 || ''}
                              options='Draft'
                              {...register('example2')}
                              onChange={onEditorContentChanged}
                              height='rich'
                            />
                          </div>
                          <div className='flex flex-col gap-y-2 mt-16'>
                            <div className='flex items-center gap-x-4'>
                              <input
                                type='checkbox'
                                className='form-checkbox'
                                checked={showExample3}
                                onChange={() => setShowExample3(!showExample3)}
                              />
                              <label htmlFor='showExample3'>Add Example 3</label>
                            </div>
                          </div>
                          {showExample3 && (
                            <>
                              <div className='flex flex-col gap-y-2 mt-2'>
                                <div className='flex items-center gap-x-4'>
                                  <span className='text-gray-800 dark:text-zinc-300 text-[16px] font-medium'>
                                    Example 3 (Gamification) <span className='text-red-500'>*</span>
                                  </span>
                                  {errors.example3 && (
                                    <span className='text-red-500'>
                                      Please enter at least 10 characters.
                                    </span>
                                  )}
                                </div>
                                <RichEditor
                                  name='example3'
                                  value={problemSetter?.example3 || ''}
                                  options='Draft'
                                  {...register('example3')}
                                  onChange={onEditorContentChanged}
                                  height='rich'
                                />
                              </div>

                              <div className='flex flex-col gap-y-2 mt-10'>
                                <span className='text-gray-800 dark:text-zinc-300 text-[16px] font-medium'>
                                  Output (Gamification)<span className='text-red-500'>*</span>
                                </span>
                                {errors?.gamification_ouput && (
                                  <span className='text-red-500'>
                                    {errors?.gamification_ouput.message}
                                  </span>
                                )}
                                <Controller
                                  name='gamification_ouput'
                                  control={control}
                                  defaultValue={problemSetter?.gamification_ouput || ''}
                                  render={({ field, fieldState }) => (
                                    <div>
                                      <input
                                        type='text'
                                        name='gamification_ouput'
                                        className='form-input w-full text-gray-300'
                                        placeholder='Gamification Ouput'
                                        {...field}
                                      />
                                      {fieldState?.error && (
                                        <span className='text-red-500'>
                                          {fieldState.error.message}
                                        </span>
                                      )}
                                    </div>
                                  )}
                                />
                              </div>

                              <>
                                <div className='flex flex-col flex-wrap -mx-3 mb-4'>
                                  <span className='px-3 text-medium font-semibold text-light_30 mb-2 dark:text-zinc-300'>
                                    Correct Option
                                  </span>
                                  <div className='w-full px-3'>
                                    <Controller
                                      name='correctGamificationOptionNumber'
                                      control={control}
                                      render={({ field }) => (
                                        <select
                                          id='correctGamificationOptionNumber'
                                          name='correctGamificationOptionNumber'
                                          className='form-select w-full text-gray-300'
                                          {...field}
                                        >
                                          {additionalOptions?.map((option, index) => (
                                            <option key={index} value={index + 1}>
                                              {index + 1}
                                            </option>
                                          ))}
                                        </select>
                                      )}
                                    />
                                    {isSubmitted && errors?.correctGamificationOptionNumber && (
                                      <p className='text-red-600 text-sm italic'>
                                        {errors?.correctGamificationOptionNumber?.message}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </>

                              {additionalOptions?.map((option, index) => (
                                <div key={index} className='mt-4'>
                                  <div className='flex flex-col flex-wrap -mx-3 mb-4'>
                                    <div className='flex justify-between items-center'>
                                      <span className='mb-3 px-3 text-gray-800 dark:text-zinc-300 text-[16px] font-medium'>
                                        Option {index + 1}
                                      </span>
                                      {index === additionalOptions.length - 1 && (
                                        <button
                                          type='button'
                                          className='mr-4'
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            removeOptions(option.id);
                                          }}
                                        >
                                          <BadgeX className='stroke-red-600' size={18} />
                                        </button>
                                      )}
                                    </div>
                                    <div className='w-full px-3'>
                                      {/* Your input field for additional options */}
                                      <Controller
                                        name={`option${index + 1}`}
                                        control={control}
                                        defaultValue={option.value} // Set the default value
                                        render={({ field }) => (
                                          <input
                                            id={`option${index + 1}`}
                                            type='text'
                                            className='form-input w-full text-gray-300'
                                            placeholder={`option ${index + 1}`}
                                            {...field}
                                          />
                                        )}
                                      />
                                    </div>
                                  </div>
                                </div>
                              ))}

                              <span>
                                <div className='mt-6'>
                                  <button
                                    type='button'
                                    onClick={addOptions}
                                    className='btn  bg-light_50 dark:bg-dark_50 w-full items-center justify-center flex'
                                  >
                                    <Plus size={18} className='stroke-zinc-600' />
                                  </button>
                                </div>
                              </span>
                            </>
                          )}
                          <div className='flex flex-col gap-y-2 mt-16'>
                            <div className='flex items-center gap-x-4'>
                              <span className='text-gray-800 dark:text-zinc-300 text-[16px] font-medium'>
                                Constraints<span className='text-red-500'>*</span>
                              </span>
                              {errors.constraints && (
                                <span className='text-red-500'>
                                  Please enter at least 10 characters.
                                </span>
                              )}
                            </div>
                            <RichEditor
                              name='constraints'
                              value={problemSetter?.constraints || ''}
                              options='Discussion'
                              {...register('constraints')}
                              onChange={onEditorContentChanged}
                              height='rich'
                            />
                          </div>
                          <div className='flex flex-col gap-y-2 mt-16 text-[16px] font-medium'>
                            <div className='flex items-center gap-x-4'>
                              <span className='text-gray-800 dark:text-zinc-300 text-[16px] font-medium'>
                                Note<span className='text-red-500'>*</span>
                              </span>
                              {errors.notes && (
                                <span className='text-red-500'>
                                  Please enter at least 10 characters.
                                </span>
                              )}
                            </div>
                            <RichEditor
                              name='notes'
                              value={problemSetter?.notes || ''}
                              options='Discussion'
                              {...register('notes')}
                              onChange={onEditorContentChanged}
                              height='rich'
                            />
                          </div>
                          <div className='flex flex-col gap-y-2 mt-16 text-[16px] font-medium'>
                            <div className='flex items-center gap-x-4'>
                              <span className='text-gray-800 dark:text-zinc-300'>
                                Input 1 (Show on IDE) <span className='text-red-500'>*</span>
                              </span>
                              {errors?.showInput1 && (
                                <span className='text-red-500'>{errors?.showInput1.message}</span>
                              )}
                            </div>
                            <Controller
                              name='showInput1'
                              // defaultValue={problem?.showInput1 || ''}
                              control={control}
                              render={({ field }) => (
                                <input
                                  type='text'
                                  name='showInput1'
                                  className='form-input w-full text-gray-300'
                                  placeholder='Input 1'
                                  {...field}
                                />
                              )}
                            />
                          </div>
                          <div className='flex flex-col gap-y-2 mt-2 text-[16px] font-medium'>
                            <div className='flex items-center gap-x-4'>
                              <span className='text-gray-800 dark:text-zinc-300'>
                                Input 2 (Show on IDE) <span className='text-red-500'>*</span>
                              </span>
                              {errors?.showInput2 && (
                                <span className='text-red-500'>{errors?.showInput2.message}</span>
                              )}
                            </div>
                            <Controller
                              name='showInput2'
                              control={control}
                              // defaultValue={problem?.showInput2 || ''}
                              render={({ field }) => (
                                <input
                                  type='text'
                                  name='showInput2'
                                  className='form-input w-full text-gray-300'
                                  placeholder='Input 2'
                                  {...field}
                                />
                              )}
                            />
                          </div>
                          <div className='flex flex-col gap-y-2 mt-2 text-[16px] font-medium'>
                            <div className='flex items-center gap-x-4'>
                              <span className='text-gray-800 dark:text-zinc-300'>
                                Facts<span className='text-red-500'>*</span>
                              </span>
                              {errors?.showInput2 && (
                                <span className='text-red-500'>{errors?.facts.message}</span>
                              )}
                            </div>
                            <Controller
                              name='facts'
                              control={control}
                              // defaultValue={problem?.showInput2 || ''}
                              render={({ field }) => (
                                <input
                                  type='text'
                                  name='facts'
                                  className='form-input w-full text-gray-300'
                                  placeholder='Input 2'
                                  {...field}
                                />
                              )}
                            />
                          </div>
                        </div>
                      </Accordion>
                    </div>
                    <div className='mt-6'>
                      <Accordion title='Section - 2' errors={errors}>
                        <div className='flex flex-col gap-y-2 mb-6'>
                          <div className='flex items-center gap-x-4'>
                            <span className='text-gray-800 dark:text-zinc-300 text-[16px] font-medium'>
                              Time Limit<span className='text-red-500'>*</span>
                            </span>
                            {errors?.cppTimeLimit && (
                              <span className='text-red-500'>{errors?.cppTimeLimit.message}</span>
                            )}
                          </div>
                          <Controller
                            name='cppTimeLimit'
                            control={control}
                            render={({ field, fieldState }) => (
                              <div>
                                <input
                                  id='number'
                                  type='number'
                                  name='cppTimeLimit'
                                  className='form-input w-full text-gray-300'
                                  placeholder='Time Limit C++'
                                  {...field}
                                />
                              </div>
                            )}
                          />
                        </div>
                        <Accordion title='C++' errors={errors}>
                          <div className='flex flex-col gap-y-6'>
                            <div className='flex flex-col gap-y-2'>
                              <div className='flex items-center gap-x-4'>
                                <span className='text-gray-800 dark:text-zinc-300'>
                                  Before code <span className='text-red-500'>*</span>
                                </span>
                                {errors?.beforePublicCpp && (
                                  <span className='text-red-500'>
                                    {errors?.beforePublicCpp.message}
                                  </span>
                                )}
                              </div>
                              <Controller
                                name='beforePublicCpp'
                                control={control}
                                defaultValue={problemSetter?.beforePublicCpp || ''}
                                render={({ field }) => (
                                  <textarea
                                    type='text'
                                    rows={10}
                                    name='beforePublicCpp'
                                    className='form-input w-full text-gray-300'
                                    placeholder='Before code '
                                    {...field}
                                  />
                                )}
                              />
                            </div>
                            <div className='flex flex-col gap-y-2'>
                              <div className='flex items-center gap-x-4'>
                                <span className='text-gray-800 dark:text-zinc-300'>
                                  Shown code on IDE <span className='text-red-500'>*</span>
                                </span>
                                {errors?.publicCpp && (
                                  <span className='text-red-500'>{errors?.publicCpp.message}</span>
                                )}
                              </div>
                              <Controller
                                name='publicCpp'
                                control={control}
                                defaultValue={problemSetter?.publicCpp || ''}
                                render={({ field }) => (
                                  <textarea
                                    type='text'
                                    rows={10}
                                    name='publicCpp'
                                    className='form-input w-full text-gray-300'
                                    placeholder='Shown code on IDE'
                                    {...field}
                                  />
                                )}
                              />
                            </div>
                            <div className='flex flex-col gap-y-2'>
                              <div className='flex items-center gap-x-4'>
                                <span className='text-gray-800 dark:text-zinc-300'>
                                  Remaning Code<span className='text-red-500'>*</span>
                                </span>
                                {errors?.privateCpp && (
                                  <span className='text-red-500'>{errors?.privateCpp.message}</span>
                                )}
                              </div>
                              <Controller
                                name='privateCpp'
                                defaultValue={problemSetter?.privateCpp || ''}
                                control={control}
                                render={({ field }) => (
                                  <textarea
                                    type='text'
                                    rows={10}
                                    name='privateCpp'
                                    className='form-input w-full text-gray-300'
                                    placeholder='Remaning Code'
                                    {...field}
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </Accordion>
                        <div className='mt-5'>
                          <Accordion title='Java' errors={errors}>
                            <div className='flex flex-col  gap-y-6'>
                              <div className='flex flex-col gap-y-2'>
                                <div className='flex items-center gap-x-4'>
                                  <span className='text-gray-800 dark:text-zinc-300'>
                                    Before code <span className='text-red-500'>*</span>
                                  </span>
                                  {errors?.beforePublicJava && (
                                    <span className='text-red-500'>
                                      {errors?.beforePublicJava.message}
                                    </span>
                                  )}
                                </div>
                                <Controller
                                  name='beforePublicJava'
                                  control={control}
                                  defaultValue={problemSetter?.beforePublicJava || ''}
                                  render={({ field }) => (
                                    <textarea
                                      type='text'
                                      rows={10}
                                      name='beforePublicJava'
                                      className='form-input w-full text-gray-300'
                                      placeholder='Before code '
                                      {...field}
                                    />
                                  )}
                                />
                              </div>
                              <div className='flex flex-col gap-y-2'>
                                <div className='flex items-center gap-x-4'>
                                  <span className='text-gray-800 dark:text-zinc-300'>
                                    Shown code on IDE <span className='text-red-500'>*</span>
                                  </span>
                                  {errors?.publicJava && (
                                    <span className='text-red-500'>
                                      {errors?.publicJava.message}
                                    </span>
                                  )}
                                </div>
                                <Controller
                                  name='publicJava'
                                  defaultValue={problemSetter?.publicJava || ''}
                                  control={control}
                                  render={({ field }) => (
                                    <textarea
                                      type='text'
                                      rows={10}
                                      name='publicJava'
                                      className='form-input w-full text-gray-300'
                                      placeholder='Shown code on IDE'
                                      {...field}
                                    />
                                  )}
                                />
                              </div>
                              <div className='flex flex-col gap-y-2'>
                                <div className='flex items-center gap-x-4'>
                                  <span className='text-gray-800 dark:text-zinc-300'>
                                    Remaning Code<span className='text-red-500'>*</span>
                                  </span>
                                  {errors?.privateJava && (
                                    <span className='text-red-500'>
                                      {errors?.privateJava.message}
                                    </span>
                                  )}
                                </div>
                                <Controller
                                  name='privateJava'
                                  defaultValue={problemSetter?.privateJava || ''}
                                  control={control}
                                  render={({ field }) => (
                                    <textarea
                                      type='text'
                                      rows={10}
                                      name='privateJava'
                                      className='form-input w-full text-gray-300'
                                      placeholder='Remaning Code'
                                      {...field}
                                    />
                                  )}
                                />
                              </div>
                            </div>
                          </Accordion>
                        </div>
                        <div className='mt-5'>
                          <Accordion title='Python' errors={errors}>
                            <div className='flex flex-col  gap-y-6'>
                              <div className='flex flex-col gap-y-2'>
                                <div className='flex items-center gap-x-4'>
                                  <span className='text-gray-800 dark:text-zinc-300'>
                                    Before code <span className='text-red-500'>*</span>
                                  </span>
                                  {errors?.beforePublicPy && (
                                    <span className='text-red-500'>
                                      {errors?.beforePublicPy.message}
                                    </span>
                                  )}
                                </div>
                                <Controller
                                  name='beforePublicPy'
                                  control={control}
                                  defaultValue={problemSetter?.beforePublicPy || ''}
                                  render={({ field }) => (
                                    <textarea
                                      type='text'
                                      rows={10}
                                      name='beforePublicPy'
                                      className='form-input w-full text-gray-300'
                                      placeholder='Before code '
                                      {...field}
                                    />
                                  )}
                                />
                              </div>
                              <div className='flex flex-col gap-y-2'>
                                <div className='flex items-center gap-x-4'>
                                  <span className='text-gray-800 dark:text-zinc-300'>
                                    Shown code on IDE <span className='text-red-500'>*</span>
                                  </span>
                                  {errors?.publicPy && (
                                    <span className='text-red-500'>{errors?.publicPy.message}</span>
                                  )}
                                </div>
                                <Controller
                                  name='publicPy'
                                  defaultValue={problemSetter?.publicPy || ''}
                                  control={control}
                                  render={({ field }) => (
                                    <textarea
                                      type='text'
                                      rows={10}
                                      name='publicPy'
                                      className='form-input w-full text-gray-300'
                                      placeholder='Shown code on IDE'
                                      {...field}
                                    />
                                  )}
                                />
                              </div>
                              <div className='flex flex-col gap-y-2'>
                                <div className='flex items-center gap-x-4'>
                                  <span className='text-gray-800 dark:text-zinc-300'>
                                    Remaning Code<span className='text-red-500'>*</span>
                                  </span>
                                  {errors?.privatePy && (
                                    <span className='text-red-500'>
                                      {errors?.privatePy.message}
                                    </span>
                                  )}
                                </div>
                                <Controller
                                  name='privatePy'
                                  defaultValue={problemSetter?.privatePy || ''}
                                  control={control}
                                  render={({ field }) => (
                                    <textarea
                                      type='text'
                                      rows={10}
                                      name='privatePy'
                                      className='form-input w-full text-gray-300'
                                      placeholder='Remaning Code'
                                      {...field}
                                    />
                                  )}
                                />
                              </div>
                            </div>
                          </Accordion>
                        </div>
                        <div className='mt-5'>
                          <Accordion title='Javascript' errors={errors}>
                            <div className='flex flex-col  gap-y-6'>
                              <div className='flex flex-col gap-y-2'>
                                <div className='flex items-center gap-x-4'>
                                  <span className='text-gray-800 dark:text-zinc-300'>
                                    Before code <span className='text-red-500'>*</span>
                                  </span>
                                  {errors?.beforePublicJs && (
                                    <span className='text-red-500'>
                                      {errors?.beforePublicJs.message}
                                    </span>
                                  )}
                                </div>
                                <Controller
                                  name='beforePublicJs'
                                  control={control}
                                  defaultValue={problemSetter?.beforePublicJs || ''}
                                  render={({ field }) => (
                                    <textarea
                                      type='text'
                                      rows={10}
                                      name='beforePublicJs'
                                      className='form-input w-full text-gray-300'
                                      placeholder='Before code '
                                      {...field}
                                    />
                                  )}
                                />
                              </div>
                              <div className='flex flex-col gap-y-2'>
                                <div className='flex items-center gap-x-4'>
                                  <span className='text-gray-800 dark:text-zinc-300'>
                                    Shown code on IDE <span className='text-red-500'>*</span>
                                  </span>
                                  {errors?.publicJs && (
                                    <span className='text-red-500'>{errors?.publicJs.message}</span>
                                  )}
                                </div>
                                <Controller
                                  name='publicJs'
                                  defaultValue={problemSetter?.publicJs || ''}
                                  control={control}
                                  render={({ field }) => (
                                    <textarea
                                      type='text'
                                      rows={10}
                                      name='publicJs'
                                      className='form-input w-full text-gray-300'
                                      placeholder='Shown code on IDE'
                                      {...field}
                                    />
                                  )}
                                />
                              </div>
                              <div className='flex flex-col gap-y-2'>
                                <div className='flex items-center gap-x-4'>
                                  <span className='text-gray-800 dark:text-zinc-300'>
                                    Remaning Code<span className='text-red-500'>*</span>
                                  </span>
                                  {errors?.privateJs && (
                                    <span className='text-red-500'>
                                      {errors?.privateJs.message}
                                    </span>
                                  )}
                                </div>
                                <Controller
                                  name='privateJs'
                                  defaultValue={problemSetter?.privateJs || ''}
                                  control={control}
                                  render={({ field }) => (
                                    <textarea
                                      type='text'
                                      rows={10}
                                      name='privateJs'
                                      className='form-input w-full text-gray-300'
                                      placeholder='Remaning Code'
                                      {...field}
                                    />
                                  )}
                                />
                              </div>
                            </div>
                          </Accordion>
                        </div>
                        <div className='mt-8 flex flex-row  items-center gap-x-8 w-full'>
                          <div className='flex flex-col gap-y-2 w-full'>
                            <div className='flex items-center gap-x-4'>
                              <span className='text-gray-800 dark:text-zinc-300'>
                                Input 1 <span className='text-red-500'>*</span>
                              </span>
                              {errors?.realInput1 && (
                                <span className='text-red-500'>{errors?.realInput1.message}</span>
                              )}
                            </div>
                            <Controller
                              name='realInput1'
                              defaultValue={problemSetter?.realInput1 || ''}
                              control={control}
                              render={({ field }) => (
                                <textarea
                                  type='text'
                                  rows={6}
                                  name='realInput1'
                                  className='form-input w-full text-gray-300'
                                  placeholder='Input 1'
                                  {...field}
                                />
                              )}
                            />
                          </div>
                          <div className='flex flex-col gap-y-2 w-full'>
                            <div className='flex items-center gap-x-4'>
                              <span className='text-gray-800 dark:text-zinc-300'>
                                Output 1 <span className='text-red-500'>*</span>
                              </span>
                              {errors?.realOutput1 && (
                                <span className='text-red-500'>{errors?.realOutput1.message}</span>
                              )}
                            </div>
                            <Controller
                              name='realOutput1'
                              control={control}
                              defaultValue={problemSetter?.realOutput1 || ''}
                              render={({ field }) => (
                                <textarea
                                  type='text'
                                  rows={6}
                                  name='realOutput1'
                                  className='form-input w-full text-gray-300'
                                  placeholder='Output 1'
                                  {...field}
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className='mt-8 flex flex-row  items-center gap-x-8 w-full'>
                          <div className='flex flex-col gap-y-2 w-full'>
                            <div className='flex items-center gap-x-4'>
                              <span className='text-gray-800 dark:text-zinc-300'>
                                Input 2 <span className='text-red-500'>*</span>
                              </span>
                              {errors?.realInput2 && (
                                <span className='text-red-500'>{errors?.realInput2.message}</span>
                              )}
                            </div>
                            <Controller
                              name='realInput2'
                              defaultValue={problemSetter?.realInput2 || ''}
                              control={control}
                              render={({ field }) => (
                                <textarea
                                  type='text'
                                  rows={6}
                                  name='realInput2'
                                  className='form-input w-full text-gray-300'
                                  placeholder='Input 2'
                                  {...field}
                                />
                              )}
                            />
                          </div>
                          <div className='flex flex-col gap-y-2 w-full'>
                            <div className='flex items-center gap-x-4'>
                              <span className='text-gray-800 dark:text-zinc-300'>
                                Output 2 <span className='text-red-500'>*</span>
                              </span>
                              {errors?.realOutput2 && (
                                <span className='text-red-500'>{errors?.realOutput2.message}</span>
                              )}
                            </div>
                            <Controller
                              name='realOutput2'
                              defaultValue={problemSetter?.realOutput2 || ''}
                              control={control}
                              render={({ field }) => (
                                <textarea
                                  type='text'
                                  rows={6}
                                  name='realOutput2'
                                  className='form-input w-full text-gray-300'
                                  placeholder='Output 2'
                                  {...field}
                                />
                              )}
                            />
                          </div>
                        </div>
                        {Array.from({ length: additionalFields }).map((_, index) => (
                          <div
                            className='mt-8 flex flex-row w-full items-center gap-x-8'
                            key={index}
                          >
                            <div className='flex flex-col gap-y-2 w-full'>
                              <div className='flex justify-between'>
                                <div className='flex items-center gap-x-4'>
                                  <span
                                    onClick={handleRemoveFields}
                                    className='text-gray-800 dark:text-zinc-300 text-[16px] font-medium'
                                  >
                                    {`Input ${index + 4}`} <span className='text-red-500'>*</span>
                                  </span>
                                </div>
                                <button onClick={handleRemoveFields}>
                                  <BadgeX className='stroke-red-600' size={18} />
                                </button>
                              </div>
                              <Controller
                                name={`realInput${index + 4}`}
                                control={control}
                                defaultValue={edgeCasesValueArray[index]?.input || ''}
                                render={({ field }) => (
                                  <textarea
                                    type='text'
                                    rows={6}
                                    name={`realInput${index + 4}`}
                                    className='form-input w-full text-gray-300'
                                    placeholder={`Input ${index + 4}`}
                                    {...field}
                                  />
                                )}
                              />
                            </div>
                            <div className='flex flex-col gap-y-2 w-full'>
                              <div className='flex justify-between'>
                                <div className='flex items-center gap-x-4'>
                                  <span className='text-gray-800 dark:text-zinc-300 text-[16px] font-medium'>
                                    {`Output ${index + 4}`} <span className='text-red-500'>*</span>
                                  </span>
                                  {/* {errors?.realInput3 && (
                                                                        <span className="text-red-500">{`Output ${index + 4} is required`}</span>
                                                                    )} */}
                                </div>
                                <button onClick={handleRemoveFields}>
                                  <BadgeX className='stroke-red-600' size={18} />
                                </button>
                              </div>

                              <Controller
                                name={`realOutput${index + 4}`}
                                control={control}
                                defaultValue={edgeCasesValueArray[index]?.output || ''}
                                render={({ field }) => (
                                  <textarea
                                    type='text'
                                    rows={6}
                                    name={`realOutput${index + 4}`}
                                    className='form-input w-full text-gray-300'
                                    placeholder={`Output ${index + 4}`}
                                    {...field}
                                  />
                                )}
                              />
                            </div>
                          </div>
                        ))}
                        <div className='mt-8'>
                          <button
                            type='button'
                            onClick={handleAddFields}
                            className='btn bg-light_50 dark:bg-dark_50 w-full items-center justify-center flex'
                          >
                            <Plus size={18} className='stroke-zinc-600' />
                          </button>
                        </div>
                        <div className='mt-10 '>
                          <div className='flex flex-col gap-y-2 w-full'>
                            <div className='flex items-center gap-x-4'>
                              <span className='text-gray-800 dark:text-zinc-300 text-[16px]'>
                                Big Input (WA) <span className='text-red-500'>*</span>
                              </span>
                              {errors?.bigInput && (
                                <span className='text-red-500'>{errors?.bigInput.message}</span>
                              )}
                            </div>
                            <Controller
                              name='bigInput'
                              control={control}
                              render={({ field }) => (
                                <input
                                  type='text'
                                  name='bigInput'
                                  className='form-input w-full text-gray-300'
                                  placeholder='Big Input 2'
                                  {...field}
                                />
                              )}
                            />
                          </div>
                          <div className='flex flex-col gap-y-2 w-full mt-4'>
                            <div className='flex items-center gap-x-4'>
                              <span className='text-gray-800 dark:text-zinc-300 text-[16px]'>
                                Big Output (WA) <span className='text-red-500'>*</span>
                              </span>
                              {errors?.bigOutput && (
                                <span className='text-red-500'>{errors?.bigOutput.message}</span>
                              )}
                            </div>
                            <Controller
                              name='bigOutput'
                              control={control}
                              render={({ field }) => (
                                <input
                                  type='text'
                                  name='bigOutput'
                                  className='form-input w-full text-gray-300'
                                  placeholder='Big Output 2'
                                  {...field}
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className='mt-10 '>
                          <div className='flex flex-col gap-y-2 w-full'>
                            <div className='flex items-center gap-x-4'>
                              <span className='text-gray-800 dark:text-zinc-300 text-[16px]'>
                                Big Input (TLE) <span className='text-red-500'>*</span>
                              </span>
                              {errors?.bigInputTle && (
                                <span className='text-red-500'>{errors?.bigInputTle.message}</span>
                              )}
                            </div>
                            <Controller
                              name='bigInputTle'
                              control={control}
                              render={({ field }) => (
                                <input
                                  type='text'
                                  name='bigInputTle'
                                  className='form-input w-full text-gray-300'
                                  placeholder='Big Input TLE'
                                  {...field}
                                />
                              )}
                            />
                          </div>
                          <div className='flex flex-col gap-y-2 w-full mt-4'>
                            <div className='flex items-center gap-x-4'>
                              <span className='text-gray-800 dark:text-zinc-300 text-[16px]'>
                                Big Output (TLE) <span className='text-red-500'>*</span>
                              </span>
                              {errors?.bigOutputTle && (
                                <span className='text-red-500'>{errors?.bigOutputTle.message}</span>
                              )}
                            </div>
                            <Controller
                              name='bigOutputTle'
                              control={control}
                              render={({ field }) => (
                                <input
                                  type='text'
                                  name='bigOutputTle'
                                  className='form-input w-full text-gray-300'
                                  placeholder='Big Output TLE'
                                  {...field}
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className='mt-6 '>
                          <div className='flex flex-col gap-y-2 w-full mt-4'>
                            <div className='flex items-center gap-x-4'>
                              <span className='text-gray-800 dark:text-zinc-300 text-[16px]'>
                                Custom Testcase Show Text <span className='text-red-500'>*</span>
                              </span>
                              {errors?.customTC && (
                                <span className='text-red-500'>{errors?.customTC.message}</span>
                              )}
                            </div>
                            <Controller
                              name='customTC'
                              defaultValue={problemSetter?.customTC || ''}
                              control={control}
                              render={({ field }) => (
                                <textarea
                                  type='text'
                                  rows={6}
                                  name='customTC'
                                  className='form-input w-full text-gray-300'
                                  placeholder='Custom Testcase Show Text'
                                  {...field}
                                />
                              )}
                            />
                          </div>
                          <div className='flex flex-col gap-y-2 mt-2'>
                            <div className='flex items-center gap-x-4'>
                              <span className='text-gray-800 dark:text-zinc-300 text-[16px] font-medium'>
                                Correct Code Language<span className='text-red-500'>*</span>
                              </span>
                              {errors?.correctCodeLang && (
                                <span className='text-red-500'>
                                  {errors?.correctCodeLang.message}
                                </span>
                              )}
                            </div>
                            <Controller
                              name='correctCodeLang'
                              control={control}
                              defaultValue=''
                              render={({ field }) => (
                                <select {...field} className='form-select w-full text-gray-300'>
                                  <option value=''>Select Correct Code Language...</option>
                                  <option value='cpp'>CPP</option>
                                  <option value='javascript'>Javascript</option>
                                  <option value='java'>Java</option>
                                  <option value='python'>Python</option>
                                </select>
                              )}
                            />
                          </div>
                          <div className='flex flex-col gap-y-2 mt-4'>
                            <div className='flex items-center gap-x-4'>
                              <span className='text-gray-800 dark:text-zinc-300 text-[16px] font-medium'>
                                Correct Code Language <span className='text-red-500'>*</span>
                              </span>
                              {errors?.correctCode && (
                                <span className='text-red-500'>{errors?.correctCode.message}</span>
                              )}
                            </div>
                            <Controller
                              name='correctCode'
                              control={control}
                              render={({ field }) => (
                                <textarea
                                  type='text'
                                  name='correctCode'
                                  rows={8}
                                  className='form-input w-full text-gray-300'
                                  placeholder='Correct Code...'
                                  {...field}
                                />
                              )}
                            />
                          </div>
                        </div>
                      </Accordion>
                    </div>

                    <div className='mt-8 flex flex-row  items-center gap-x-4'>
                      <button
                        className='btn text-[16px] font-medium bg-brand_50 border-brand_50 text-zinc-100'
                        onClick={handleSubmit(handleOnSubmit)}
                      >
                        Save
                      </button>
                      <button
                        onClick={(e) => handlePreview(e)}
                        className='btn text-[16px] font-medium'
                      >
                        Preview
                      </button>
                      <button
                        type='button'
                        onClick={(e) => {
                          e.stopPropagation();
                          dispatch(clearCacheJudge());
                        }}
                        className='btn text-[16px] font-medium'
                      >
                        Clear Cache Post Save
                      </button>
                    </div>
                  </form>

                  {/* Section - 3 */}

                  <div className='dark:bg-dark_50  text-slate-700 mt-6'>
                    <Accordion title='Section - 3' className='mb-4'>
                      <form
                        id='inputOutputForm'
                        onSubmit={handleSubmitIO(onSubmitIO)}
                        className='space-y-6'
                      >
                        {/* <div className="p-6 space-y-6"> */}
                        <Accordion title='Input and Output Generator'>
                          <div className='space-y-4'>
                            <h2 className='text-xl font-semibold text-slate-700 dark:text-zinc-300'>
                              Input and Output Generator
                            </h2>
                            <div>
                              <h3 className='text-lg font-medium text-slate-700 mb-2 dark:text-zinc-300 '>
                                Input
                              </h3>
                              <div className='space-y-3'>
                                {watchIO('input_structure').map((input, index) => (
                                  <div key={index} className='flex space-x-3 items-center'>
                                    <input
                                      placeholder='Label Name'
                                      onChange={(e) => handleLabelChange(index, e.target.value)}
                                      className='flex-grow form-input p-2border 
                                                                            text-slate-700
                                                                            border-gray-600 rounded focus:outline-none 
                                                                            dark:text-zinc-300'
                                      type='text'
                                      required
                                      autoComplete='off'
                                      defaultValue={
                                        problemSetter?.inputAndOutputStructure?.input_structure[
                                          index
                                        ]?.label || input.label
                                      }
                                    />
                                    <select
                                      {...registerIO(`input_structure.${index}.type`)}
                                      className='flex-grow p-2 form-select text-gray-300 dark:text-zinc-300'
                                    >
                                      <option value='integer'>Integer</option>
                                      <option value='array'>Array</option>
                                      <option value='string'>String</option>
                                      <option value='boolean'>Boolean</option>
                                      <option value='char'>Char</option>
                                      <option value='float'>Float</option>
                                      <option value='2d-array'>2D Array</option>
                                      <option value='2d-array-dynamic'>2D Array Dynamic</option>
                                    </select>
                                    {watchIO(`input_structure.${index}.type`) === 'array' && (
                                      <select
                                        {...registerIO(`input_structure.${index}.subtype`)}
                                        className='flex-grow p-2 form-select text-gray-300 dark:text-zinc-300'
                                        defaultValue={
                                          problemSetter?.inputAndOutputStructure?.input_structure[
                                            index
                                          ]?.subtype || 'integer'
                                        }
                                        required
                                      >
                                        <option value='integer'>Integer</option>
                                        <option value='string'>String</option>
                                        <option value='boolean'>Boolean</option>
                                        <option value='char'>Char</option>
                                        <option value='float'>Float</option>
                                      </select>
                                    )}
                                    {watchIO(`input_structure.${index}.type`) === '2d-array' && (
                                      <select
                                        {...registerIO(`input_structure.${index}.subtype`)}
                                        className='flex-grow p-2 form-select text-gray-300 dark:text-zinc-300'
                                        defaultValue={
                                          problemSetter?.inputAndOutputStructure?.input_structure[
                                            index
                                          ]?.subtype || 'integer'
                                        }
                                        required
                                      >
                                        <option value='integer'>Integer</option>
                                        <option value='string'>String</option>
                                        <option value='boolean'>Boolean</option>
                                        <option value='char'>Char</option>
                                        <option value='float'>Float</option>
                                      </select>
                                    )}
                                    {watchIO(`input_structure.${index}.type`) ===
                                      '2d-array-dynamic' && (
                                      <select
                                        {...registerIO(`input_structure.${index}.subtype`)}
                                        className='flex-grow p-2 form-select text-gray-300 dark:text-zinc-300'
                                        defaultValue={
                                          problemSetter?.inputAndOutputStructure?.input_structure[
                                            index
                                          ]?.subtype || 'integer'
                                        }
                                        required
                                      >
                                        <option value='integer'>Integer</option>
                                        <option value='string'>String</option>
                                        <option value='boolean'>Boolean</option>
                                        <option value='char'>Char</option>
                                        <option value='float'>Float</option>
                                      </select>
                                    )}
                                    <button
                                      type='button'
                                      onClick={() => removeLabel(index)}
                                      className='px-3 py-2 btn bg-red-600 text-slate-700 rounded hover:bg-red-700 transition duration-150 ease-in-out dark:text-zinc-300'
                                    >
                                      Delete Label
                                    </button>
                                  </div>
                                ))}
                              </div>
                              <button
                                type='button'
                                onClick={() => {
                                  setValueIO('input_structure', [
                                    ...watchIO('input_structure'),
                                    { label: 'nums', type: 'array', subtype: 'integer' },
                                  ]);
                                }}
                                className='mt-3 px-4 py-2 btn'
                              >
                                Add more label
                              </button>
                            </div>

                            <div>
                              <h3 className='text-lg font-medium text-slate-700 mb-2 dark:text-zinc-300'>
                                Output Type
                              </h3>
                              <select
                                {...registerIO('output_structure.type')}
                                className='w-full p-2 form-select text-gray-300'
                                defaultValue={watchIO('output_structure.type')}
                              >
                                <option value='integer'>Integer</option>
                                <option value='array'>Array</option>
                                <option value='string'>String</option>
                                <option value='boolean'>Boolean</option>
                              </select>
                            </div>
                          </div>
                          <h2 className='text-xl font-semibold text-slate-700 dark:text-zinc-300'>
                            Test Cases
                          </h2>
                          <Accordion title='Test Cases' className='mb-4'>
                            {watchIO('testcases').map((field, index) => (
                              <div key={field.id} className='space-y-3 p-4 rounded-lg'>
                                <h3 className='text-lg font-medium text-slate-700 dark:text-zinc-300'>
                                  Testcase {index + 1}
                                </h3>
                                {watchIO('input_structure').map((input, i) => (
                                  <div key={i} className='flex items-center space-x-3'>
                                    <label className='text-slate-700 w-1/4 dark:text-zinc-300'>
                                      {input.label}:
                                    </label>
                                    <input
                                      {...registerIO(`testcases.${index}.inputs.${input.label}`)}
                                      placeholder='Input Value'
                                      className='flex-grow p-2 rounded text-slate-700 focus:outline-none dark:text-zinc-300'
                                      defaultValue={field.inputs[input.label]}
                                      type='text'
                                      required
                                      autoComplete='off'
                                    />
                                  </div>
                                ))}
                                <div className='flex items-center space-x-3'>
                                  <label className='text-slate-700 w-1/4 dark:text-zinc-300'>
                                    parsedInput:
                                  </label>
                                  <textarea
                                    rows={5}
                                    {...registerIO(`testcases.${index}.parsedInput`)}
                                    placeholder='Parsed Input Value'
                                    defaultValue={field.parsedInput}
                                    className='flex-grow p-2 rounded text-slate-700 focus:outline-none form-textarea dark:text-zinc-300'
                                    type='text'
                                    autoComplete='off'
                                  />
                                </div>
                                <div className='flex items-center space-x-3'>
                                  <label className='text-slate-700 w-1/4 dark:text-zinc-300'>
                                    parsedOutput:
                                  </label>
                                  <textarea
                                    rows={5}
                                    {...registerIO(`testcases.${index}.parsedOutput`)}
                                    placeholder='Output Value'
                                    defaultValue={field.parsedOutput}
                                    className='flex-grow p-2 rounded text-slate-700 focus:outline-none form-textarea dark:text-zinc-300'
                                    type='text'
                                    autoComplete='off'
                                  />
                                </div>
                              </div>
                            ))}
                          </Accordion>
                          <button type='submit' className='btn w-auto font-medium mx-2 mt-4 '>
                            Run Encoder Function
                          </button>

                          <div className='p-6 space-y-6'>
                            <h2 className='text-xl font-semibold text-slate-700 dark:text-zinc-300'>
                              Output
                            </h2>
                            <div className='p-4 rounded-lg space-y-4'>
                              {!encoderError &&
                                output.map((out, index) => (
                                  <div
                                    key={index}
                                    className='border border-gray-600 p-3 rounded-lg space-y-2'
                                  >
                                    <div className='text-gray-300 '>
                                      <span className='font-semibold text-slate-700'>
                                        Test Case {index + 1} Encoded Input:
                                      </span>
                                    </div>
                                    <pre className='text-green-500 border border-gray-600 p-2 rounded-lg text-base'>
                                      {out.input}
                                    </pre>
                                  </div>
                                ))}
                              {encoderError && <div className='text-red-500'>{encoderError}</div>}
                            </div>
                          </div>
                        </Accordion>
                        {/* </div> */}
                        {/* <div className="p-6"> */}
                        <Accordion title='Problem Logical Data' className='mb-4'>
                          <div className='flex flex-col space-y-3 mb-4'>
                            <div className='space-y-1'>
                              <label className='text-slate-700 dark:text-zinc-300'>
                                CPP Time Limit
                              </label>
                              <input
                                {...registerIO('problemLogicalData.cppTimeLimit')}
                                placeholder='CPP Time Limit'
                                className='form-input w-full text-gray-300'
                                defaultValue={problemSetter?.problemLogicalData?.cppTimeLimit || 2}
                                type='number'
                              />
                            </div>
                          </div>

                          <div className='flex flex-col space-y-3 mb-4'>
                            <div className='space-y-1'>
                              <label className='text-slate-700 dark:text-zinc-300'>
                                Testcase Generator Code
                              </label>
                              <textarea
                                {...registerIO('problemLogicalData.testCaseGeneratorCode')}
                                placeholder='TestCase Code Generator'
                                className='form-input w-full text-gray-300'
                                defaultValue={
                                  problemSetter?.problemLogicalData?.testCaseGeneratorCode || ''
                                }
                                type='text'
                                rows='5'
                              />
                            </div>
                          </div>

                          {problemLogicalData && (
                            <div className='space-y-4 mb-4'>
                              {['cpp', 'java', 'python', 'javascript'].map((lang, index) => (
                                <Accordion
                                  key={index}
                                  title={`${lang.charAt(0).toUpperCase() + lang.slice(1)} Logical Data`}
                                  className='my-4'
                                >
                                  <h3 className='font-semibold text-slate-700 dark:text-zinc-300'>
                                    {lang.toUpperCase()}
                                  </h3>

                                  <div className='flex flex-col space-y-3'>
                                    <div className='space-y-1'>
                                      <label className='text-slate-700 dark:text-zinc-300'>
                                        Header Code
                                      </label>
                                      <textarea
                                        {...registerIO(`problemLogicalData.${lang}.headerCode`)}
                                        placeholder='Header Code'
                                        className='form-input w-full text-gray-300'
                                        defaultValue={
                                          problemSetter?.problemLogicalData?.[lang]?.headerCode ||
                                          ''
                                        }
                                        rows='5'
                                      />
                                    </div>

                                    <div className='space-y-1'>
                                      <label className='text-slate-700 dark:text-zinc-300'>
                                        Show Code on IDE
                                      </label>
                                      <textarea
                                        {...registerIO(`problemLogicalData.${lang}.showCodeOnIDE`)}
                                        placeholder='Show On IDE'
                                        className='form-input w-full text-gray-300'
                                        defaultValue={
                                          problemSetter?.problemLogicalData?.[lang]
                                            ?.showCodeOnIDE || ''
                                        }
                                        rows='5'
                                      />
                                    </div>

                                    <div className='space-y-1'>
                                      <label className='text-slate-700 dark:text-zinc-300'>
                                        Driver Code
                                      </label>
                                      <textarea
                                        {...registerIO(`problemLogicalData.${lang}.driverCode`)}
                                        placeholder='Driver Code'
                                        className='form-input w-full text-gray-300'
                                        defaultValue={
                                          problemSetter?.problemLogicalData?.[lang]?.driverCode ||
                                          ''
                                        }
                                        rows='5'
                                      />
                                    </div>
                                  </div>
                                </Accordion>
                              ))}
                            </div>
                          )}
                          <Accordion title='Correct Code and Language' className='mb-4'>
                            <div className='flex flex-col space-y-3'>
                              <div className='space-y-1'>
                                <label className='text-slate-700 dark:text-zinc-300'>
                                  Correct Code Language
                                </label>
                                <select
                                  {...registerIO(
                                    'problemLogicalData.correctCodeAndLanguage.correctCodeLanguage',
                                  )}
                                  className='form-select w-full p-2 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 text-slate-700 dark:text-zinc-300'
                                  defaultValue={
                                    problemSetter?.problemLogicalData?.correctCodeAndLanguage
                                      ?.correctCodeLanguage || 'cpp'
                                  }
                                >
                                  <option value='cpp'>C++</option>
                                  <option value='java'>Java</option>
                                  <option value='python'>Python</option>
                                  <option value='javascript'>JavaScript</option>
                                </select>
                              </div>

                              <div className='space-y-1'>
                                <label className='text-slate-700 dark:text-zinc-300'>
                                  Correct Code
                                </label>
                                <textarea
                                  {...registerIO(
                                    'problemLogicalData.correctCodeAndLanguage.correctCode',
                                  )}
                                  placeholder='Enter the correct code here'
                                  className='form-textarea w-full p-2 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 text-slate-700 dark:text-zinc-300'
                                  defaultValue={
                                    problemSetter?.problemLogicalData?.correctCodeAndLanguage
                                      ?.correctCode || ''
                                  }
                                  rows='6'
                                />
                              </div>
                            </div>
                          </Accordion>
                          <button
                            onClick={generateDriverCodeHandler}
                            type='button'
                            className='btn w-auto font-medium mx-2 mt-4 bg-green-600'
                          >
                            Generate Driver Code with AI
                          </button>
                        </Accordion>
                        {/* </div> */}
                        {/* LargeTestFiles */}
                        {/* <div className="p-6 space-y-6"> */}
                        <Accordion title='Hidden TestCases' className='mb-4'>
                          {problemLogicalData && (
                            <div className='space-y-4'>
                              {[
                                'largeInputFileWA',
                                'largeOutputFileWA',
                                'largeInputFileTLE',
                                'largeOutputFileTLE',
                              ].map((file, index) => (
                                <div key={index} className='flex flex-col space-y-3'>
                                  <div className='space-y-1'>
                                    <label className='text-slate-700 dark:text-zinc-300'>
                                      {file
                                        .replace(/([A-Z](?=[^TLE|^WA]))/g, ' $1') // Add spaces before uppercase letters, except for WA and TLE
                                        .replace(/^./, (str) => str.toUpperCase())}{' '}
                                    </label>
                                    <input
                                      {...registerIO(`hiddenTestCases.${file}`)}
                                      placeholder={file
                                        .replace(/([A-Z](?=[^TLE|^WA]))/g, ' $1') // Same label formatting for placeholder
                                        .replace(/^./, (str) => str.toUpperCase())}
                                      className={`form-input w-full p-2 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 text-slate-700 dark:text-zinc-300 ${
                                        errorsIO.hiddenTestCases?.[file] ? 'border-red-500' : ''
                                      }`}
                                      defaultValue={problemSetter?.hiddenTestCases?.[file] || ''}
                                    />
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </Accordion>
                        <button type='submit' className='btn w-auto font-medium mx-2 '>
                          Save Section 3
                        </button>
                      </form>
                    </Accordion>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default ProblemSetterForm;
