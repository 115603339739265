import OPENAI from 'openai';
import { zodResponseFormat } from 'openai/helpers/zod';
import { z } from 'zod';

const openai = new OPENAI({
  apiKey: process.env.REACT_APP_OPEN_AI_KEY,
  dangerouslyAllowBrowser: true,
});

const driverCodes = z.object({
  cppDriverCode: z.string(),
  javaDriverCode: z.string(),
  pythonDriverCode: z.string(),
  javascriptDriverCode: z.string(),
  correctCode: z.string(),
});

export const generateDriverCode = async (
  cppDriverCode,
  javaDriverCode,
  pythonDriverCode,
  javascriptDriverCode,
  correctCode,
) => {
  // console.log(cppDriverCode);
  // console.log(javaDriverCode);
  // console.log(pythonDriverCode);
  // console.log(javascriptDriverCode);
  // console.log(correctCode);

  const prompt = `
                Please modify both the **driver code** and the provided **correct code snippets** for each language (C++, Java, Python, and Node.js) by adding the following three lines **immediately after the \`while(t--)\` loop** for each test case:

                1. string testCase;
                2. cin >> testCase;
                3. cout << testCase << endl;
                
                **Important Instructions:**
                - Add these lines directly after the \`while(t--)\` loop in both the driver code and the corresponding correct code snippets. Each language should handle the input/output accordingly (C++ uses cin/cout; Java, Python, and Node.js should have equivalent input/output handling).
                - Do **not** add any headers, imports, or comments if they are not already present in the code. Just modify the code to include these three lines after the \`while(t--)\` loop.
                - Ensure that both the driver code and the correct code snippets remain as provided, without any additional logic, imports, or changes.
                - Carefully review and make sure the lines are added only after the \`while(t--)\` loop for both sections.
                
                Here are the current driver codes:
                
                **C++ Driver Code:**
                ${cppDriverCode}
                
                **Java Driver Code:**
                ${javaDriverCode}
                
                **Python Driver Code:**
                ${pythonDriverCode}
                
                **Node.js Driver Code:**
                ${javascriptDriverCode}
                
                Here are the correct code snippets:
                
                **Correct Code of Problems:**
                ${correctCode}
                
                Please return the updated **driver code** and **correct code snippets** for each language with the specified lines added after the \`while(t--)\` loop, without adding anything extra.
              `;

  const completion = await openai.beta.chat.completions.parse({
    model: 'gpt-4o-2024-08-06',
    messages: [
      {
        role: 'system',
        content: prompt,
      },
    ],
    response_format: zodResponseFormat(driverCodes, 'driverCodes'),
  });

  const event = completion.choices[0].message.parsed;
  return event;
};

export default generateDriverCode;
