import { setPendingStories, setApprovedStories, setDisapprovedStories } from "../../slices/successStoriesSlice"
import { successStoriesEndpoints } from "../api"
import { apiConnector } from "../apiConnector"
import toast from 'react-hot-toast'

const { GET_APPROVED_STORIES, GET_PENDING_STORIES, GET_DISAPPROVED_STORIES, UPDATE_STATUS } = successStoriesEndpoints;

export function getPendingStories(start_time, end_time, pageNumber = 1) {
    return async(dispatch) => {
        try {
            const response = await apiConnector("GET", `${GET_PENDING_STORIES}?page=${pageNumber}&start_time=${start_time}&end_time=${end_time}`);
            if(response.data.success){
                dispatch(setPendingStories({
                    pendingStories: response.data.pendingStories,
                    page: pageNumber
                }));           
            }
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || "An error occurred");
        }
    }
}

export function getApprovedStories(start_time, end_time, pageNumber = 1) {
    return async(dispatch) => {
        try {
            const response = await apiConnector("GET", `${GET_APPROVED_STORIES}?page=${pageNumber}&start_time=${start_time}&end_time=${end_time}`);
            if(response.data.success){
                dispatch(setApprovedStories({
                    approvedStories: response.data.approvedStories,
                    page: pageNumber
                }));           
            }
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || "An error occurred");
        }
    }
}

export function getDisapprovedStories(start_time, end_time, pageNumber = 1) {
    return async(dispatch) => {
        try {
            const response = await apiConnector("GET", `${GET_DISAPPROVED_STORIES}?page=${pageNumber}&start_time=${start_time}&end_time=${end_time}`);
            if(response.data.success){
                dispatch(setDisapprovedStories({
                    disapprovedStories: response.data.disapprovedStories,
                    page: pageNumber
                }));           
            }
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || "An error occurred");
        }
    }
}

export function updateStatus(id, approval_status, start_time, end_time, pageNumber) {
    return async(dispatch) => {
        try {
            const response = await apiConnector("POST", `${UPDATE_STATUS}`, {id, approval_status});
            if(response.data.success) {
                dispatch(getPendingStories(start_time, end_time, pageNumber));
                dispatch(getApprovedStories(start_time, end_time, pageNumber));
                dispatch(getDisapprovedStories(start_time, end_time, pageNumber));
                toast.success(response.data.message);
            } else {
                toast.error(response.data.message || "Failed to update status");
            }
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || "An error occurred");
        }
    }
}