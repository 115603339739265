export function encoderFunction(data) {
  try {
    let inputStructure = parseJSON(data.input_structure);
    let testcases = parseJSON(data.testcases);

    if (!Array.isArray(inputStructure)) {
      throw new Error("Input structure should be an array.");
    }

    if (!Array.isArray(testcases)) {
      throw new Error("Test cases should be an array.");
    }

    const parsedInput = testcases.map((testcase) => {
      inputStructure.forEach((inputDesc) => {
        const value = testcase.inputs[inputDesc.label];

        if (value === undefined) {
          throw new Error(`Missing input value for label: ${inputDesc.label}`);
        }

        validateInput(value, inputDesc.type, inputDesc.subtype);
      });

      const inputLines = inputStructure
        .map((inputDesc) => {
          const value = testcase.inputs[inputDesc.label];
          return formatInput(value, inputDesc.type, inputDesc.subtype);
        })
        .join("\n");

      return {
        input: `1\nTestCase-1\n${inputLines}`,
      };
    });

    return parsedInput;
  } catch (error) {
    console.error(error);
    throw new Error(`${error.message}`);
  }
}

function parseJSON(data) {
  if (typeof data === "string") {
    try {
      return JSON.parse(data);
    } catch (error) {
      throw new Error(`Error parsing JSON: ${error.message}`);
    }
  }
  return data;
}

function parseArrayString(str) {
  // Replace single quotes with double quotes, but not within already double-quoted strings
  str = str.replace(/('(?=[^"]*(?:"[^"]*"[^"]*)*$))/g, '"');
  
  // Now parse the string as JSON
  try {
    return JSON.parse(str);
  } catch (error) {
    throw new Error(`Invalid array format: ${str}`);
  }
}

function validateInput(value, type, subtype) {
  switch (type) {
    case "array":
      validateArray(value, subtype);
      break;
    case "2d-array":
      validate2DArray(value, subtype);
      break;
    case "2d-array-dynamic":
      validate2DArrayDynamic(value, subtype);
      break;
    case "integer":
      validateInteger(value);
      break;
    case "float":
      validateFloat(value);
      break;
    case "string":
      validateString(value);
      break;
    case "char":
      validateChar(value);
      break;
    case "boolean":
      validateBoolean(value);
      break;
    default:
      throw new Error(`Unhandled input type: ${type}`);
  }
}

function validateArray(value, subtype) {
  if (value === '[]') return; // Allow empty arrays

  let cleanedValue;
  try {
    cleanedValue = parseArrayString(value);
  } catch (error) {
    throw new Error(`Invalid array format: ${value}`);
  }

  if (!Array.isArray(cleanedValue)) {
    throw new Error(`Value is not a valid array: ${value}`);
  }

  cleanedValue.forEach((item, index) => {
    if (item === null) return; // Allow null values in arrays for linked lists, trees, graphs, etc.
    switch (subtype) {
      case "integer":
        if (!Number.isInteger(Number(item))) {
          throw new Error(`Invalid integer value "${item}" in array at index ${index}`);
        }
        break;
      case "float":
        if (isNaN(Number(item))) {
          throw new Error(`Invalid float value "${item}" in array at index ${index}`);
        }
        break;
      case "string":
        if (typeof item !== "string") {
          throw new Error(`Invalid string value "${item}" in array at index ${index}`);
        }
        break;
      case "char":
        if (typeof item !== "string" || [...item].length !== 1) {
          throw new Error(`Invalid char value "${item}" in array at index ${index}. Each char must be a single Unicode character.`);
        }
        break;
      case "boolean":
        if (item !== true && item !== false && item !== "true" && item !== "false") {
          throw new Error(`Invalid boolean value "${item}" in array at index ${index}`);
        }
        break;
      default:
        throw new Error(`Unhandled array subtype: ${subtype}`);
    }
  });
}

function validate2DArray(value, subtype) {
  if (value === '[]') return; // Allow empty 2D arrays

  let cleaned2DValue;
  try {
    cleaned2DValue = parseArrayString(value);
  } catch (error) {
    throw new Error(`Invalid 2D array format: ${value}`);
  }

  if (!Array.isArray(cleaned2DValue) || !cleaned2DValue.every(Array.isArray)) {
    throw new Error(`Value is not a valid 2D array: ${value}`);
  }

  const rowLengths = cleaned2DValue.map(row => row.length);
  if (new Set(rowLengths).size !== 1) {
    throw new Error("All rows in 2D array must have the same length");
  }

  cleaned2DValue.forEach((row, rowIndex) => {
    row.forEach((item, colIndex) => {
      if (item === null) return; // Allow null values in 2D arrays
      switch (subtype) {
        case "integer":
          if (!Number.isInteger(Number(item))) {
            throw new Error(`Invalid integer value "${item}" in 2D array at position [${rowIndex}][${colIndex}]`);
          }
          break;
        case "float":
          if (isNaN(Number(item))) {
            throw new Error(`Invalid float value "${item}" in 2D array at position [${rowIndex}][${colIndex}]`);
          }
          break;
        case "char":
          if (typeof item !== "string" || [...item].length !== 1) {
            throw new Error(`Invalid char value "${item}" in 2D array at position [${rowIndex}][${colIndex}]. Each char must be a single Unicode character.`);
          }
          break;
        case "string":
          if (typeof item !== "string") {
            throw new Error(`Invalid string value "${item}" in 2D array at position [${rowIndex}][${colIndex}]`);
          }
          break;
        case "boolean":
          if (item !== true && item !== false && item !== "true" && item !== "false") {
            throw new Error(`Invalid boolean value "${item}" in 2D array at position [${rowIndex}][${colIndex}]`);
          }
          break;
        default:
          throw new Error(`Unhandled 2D array subtype: ${subtype}`);
      }
    });
  });
}


function validate2DArrayDynamic(value, subtype) {
  if (value === '[]') return; // Allow empty 2D arrays

  let cleaned2DValue;
  try {
    cleaned2DValue = parseArrayString(value);
  } catch (error) {
    throw new Error(`Invalid 2D array format: ${value}`);
  }

  if (!Array.isArray(cleaned2DValue) || !cleaned2DValue.every(Array.isArray)) {
    throw new Error(`Value is not a valid 2D array: ${value}`);
  }

  cleaned2DValue.forEach((row, rowIndex) => {
    row.forEach((item, colIndex) => {
      if (item === null) return; // Allow null values in 2D arrays
      switch (subtype) {
        case "integer":
          if (!Number.isInteger(Number(item))) {
            throw new Error(`Invalid integer value "${item}" in 2D array at position [${rowIndex}][${colIndex}]`);
          }
          break;
        case "float":
          if (isNaN(Number(item))) {
            throw new Error(`Invalid float value "${item}" in 2D array at position [${rowIndex}][${colIndex}]`);
          }
          break;
        case "char":
          if (typeof item !== "string" || [...item].length !== 1) {
            throw new Error(`Invalid char value "${item}" in 2D array at position [${rowIndex}][${colIndex}]. Each char must be a single Unicode character.`);
          }
          break;
        case "string":
          if (typeof item !== "string") {
            throw new Error(`Invalid string value "${item}" in 2D array at position [${rowIndex}][${colIndex}]`);
          }
          break;
        case "boolean":
          if (item !== true && item !== false && item !== "true" && item !== "false") {
            throw new Error(`Invalid boolean value "${item}" in 2D array at position [${rowIndex}][${colIndex}]`);
          }
          break;
        default:
          throw new Error(`Unhandled 2D array subtype: ${subtype}`);
      }
    });
  });
}

function validateInteger(value) {
  const num = Number(value);
  if (!Number.isInteger(num) || num > Number.MAX_SAFE_INTEGER || num < Number.MIN_SAFE_INTEGER) {
    throw new Error(`"${value}" is not a valid integer or is out of range.`);
  }
}

function validateFloat(value) {
  const num = Number(value);
  if (isNaN(num) || !isFinite(num)) {
    throw new Error(`"${value}" is not a valid float.`);
  }
}

function validateString(value) {
  if (typeof value !== "string") {
    throw new Error(`"${value}" is not a valid string.`);
  }
}

function validateChar(value) {
  if (typeof value !== "string" || [...value].length !== 1) {
    throw new Error(`"${value}" is not a valid char. Each char must be a single Unicode character.`);
  }
}

function validateBoolean(value) {
  if (value !== true && value !== false && value !== "true" && value !== "false") {
    throw new Error(`"${value}" is not a valid boolean. Only true or false are allowed.`);
  }
}

function formatInput(value, type, subtype) {
  switch (type) {
    case "array":
      return formatArray(value, subtype);
    case "2d-array":
      return format2DArray(value, subtype);
    case "2d-array-dynamic":
      return format2DDynamicArray(value, subtype);
    case "integer":
      return formatInteger(value);
    case "float":
      return formatFloat(value);
    case "string":
      return formatString(value);
    case "char":
      return formatChar(value);
    case "boolean":
      return formatBoolean(value);
    default:
      throw new Error(`Unhandled input type: ${type}`);
  }
}

function formatArray(value, subtype) {
  const array = parseArrayString(value);
  const length = array.length;

  let formattedArray;
  switch (subtype) {
    case "integer":
    case "float":
      formattedArray = array.map(item => item === null ? "null" : Number(item).toString());
      break;
    case "string":
    case "char":
      formattedArray = array.map(item => item === null ? "null" : item);
      break;
    case "boolean":
      formattedArray = array.map(item => {
        if (item === null) return "null";
        return (item === true || item === "true") ? "1" : "0";
      });
      break;
    case "mixed":
      formattedArray = array.map(item => item === null ? "null" : String(item));
      break;
    default:
      throw new Error(`Unhandled array subtype: ${subtype}`);
  }

  return `${length}\n${formattedArray.join(" ")}`;
}

function format2DArray(value, subtype) {
  const array2D = parseArrayString(value);
  const rows = array2D.length;
  const cols = array2D[0] ? array2D[0].length : 0;

  let formattedRows;
  switch (subtype) {
    case "integer":
    case "float":
      formattedRows = array2D.map(row => row.map(item => item === null ? "null" : Number(item).toString()).join(" "));
      break;
    case "string":
    case "char":
      formattedRows = array2D.map(row => row.map(item => item === null ? "null" : item).join(" "));
      break;
    case "boolean":
      formattedRows = array2D.map(row => row.map(item => {
        if (item === null) return "null";
        return (item === true || item === "true") ? "1" : "0";
      }).join(" "));
      break;
    case "mixed":
      formattedRows = array2D.map(row => row.map(item => {
        if (item === null) return "null";
        if (typeof item === "string") return item;
        return String(item);
      }).join(" "));
      break;
    default:
      throw new Error(`Unhandled 2D array subtype: ${subtype}`);
  }

  return `${rows} ${cols}\n${formattedRows.join("\n")}`;
}

function format2DDynamicArray(value, subtype) {
  const array2D = parseArrayString(value);
  console.log(array2D);
  const rows = array2D.length;

  let formattedRows = array2D.map(row => {
    const length = row.length;

    if(length === 0) return length;
   
    let formattedRow;
    switch (subtype) {
      case "integer":
      case "float":
        formattedRow = row.map(item => item === null ? "null" : Number(item).toString()).join(" ");
        break;
      case "string":
      case "char":
        formattedRow = row.map(item => item === null ? "null" : item).join(" ");
        break;
      case "boolean":
        formattedRow = row.map(item => {
          if (item === null) return "null";
          return (item === true || item === "true") ? "1" : "0";
        }).join(" ");
        break;
      case "mixed":
        formattedRow = row.map(item => item === null ? "null" : String(item)).join(" ");
        break;
      default:
        throw new Error(`Unhandled 2D array subtype: ${subtype}`);
    }
    return `${length}\n${formattedRow}`;
  });

  return `${rows}\n${formattedRows.join('\n')}`;
}

function formatInteger(value) {
  return Number(value).toString();
}

function formatFloat(value) {
  return Number(value).toString();
}

function formatString(value) {
  return value.toString();
}

function formatChar(value) {
  return value.toString();
}

function formatBoolean(value) {
  return (value === true || value === "true") ? "1" : "0";
}