/* eslint-disable no-unused-vars */
import React, { useMemo } from 'react';
import {
    Line, LineChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';

const ProgressPercentageChart = React.memo(({ data }) => {
    const chartData = useMemo(() => {
        const dataCopy = [...data];
        let cumulativeCount = 0;

        return dataCopy.reverse().map((item) => {
            cumulativeCount += item.user_count;

            return {
                progress_percentage: item.progress_percentage,
                user_count: item.user_count,
                cumulative_user_count: cumulativeCount,
            };
        }).reverse();
    }, [data]);

    return (
        <div>
            <h3 className='text-xl font-semibold text-gray-800 dark:text-gray-200 mb-4'>User Progress Distribution</h3>
            <ResponsiveContainer width="100%" height={450}>
                <LineChart 
                    data={chartData}
                    margin={{ top: 20, right: 30, left: 20, bottom: 40 }} 
                >
                    <CartesianGrid strokeDasharray="3 3" stroke={window.matchMedia('(prefers-color-scheme: dark)').matches ? '#444' : '#808080'} />
                    <XAxis 
                        dataKey="progress_percentage" 
                        stroke={window.matchMedia('(prefers-color-scheme: dark)').matches ? '#808080' : '#333'} 
                        tick={{ fontSize: 12, fill: window.matchMedia('(prefers-color-scheme: dark)').matches ? '#808080' : '#333' }} 
                        label={{ 
                            value: 'Progress Percentage (%)', 
                            position: 'insideBottom', 
                            offset: -2, 
                            fill: window.matchMedia('(prefers-color-scheme: dark)').matches ? '#808080' : '#333' 
                        }}
                        angle={-45}
                        textAnchor="end"
                        height={60}
                    />
                    <YAxis 
                        stroke={window.matchMedia('(prefers-color-scheme: dark)').matches ? '#808080' : '#333'} 
                        tick={{ fontSize: 12, fill: window.matchMedia('(prefers-color-scheme: dark)').matches ? '#808080' : '#333' }} 
                        allowDecimals={false}
                        domain={[0, 'dataMax + 10']}
                        label={{ 
                            value: 'Number of Users', 
                            angle: -90, 
                            position: 'insideLeft', 
                            fill: window.matchMedia('(prefers-color-scheme: dark)').matches ? '#808080' : '#333' 
                        }}
                        padding={{ top: 20, bottom: 10 }}
                    />
                    <Tooltip 
                        contentStyle={{
                            backgroundColor: window.matchMedia('(prefers-color-scheme: dark)').matches ? '#333' : '#fff',
                            color: window.matchMedia('(prefers-color-scheme: dark)').matches ? '#fff' : '#000'
                        }}
                        labelFormatter={(label) => `Progress Percentage: ${label}%`} 
                    />
                    <Legend 
                        wrapperStyle={{ color: window.matchMedia('(prefers-color-scheme: dark)').matches ? '#ccc' : '#333' }} 
                    />
                    <Line type="monotone" dataKey="user_count" stroke="#ff7300" name="Number of Users" padding={{ top: 2 }}/>
                    <Line type="monotone" dataKey="cumulative_user_count" stroke="#387908" name="Cumulative User Count" padding={{ top: 2 }}/>
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
});

export default ProgressPercentageChart;
