/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from '../components/Sidebar/Sidebar';
import Header from '../components/Header/Header';
import DateRangePicker from '../components/DateRangePicker';
import Tabs from '../components/Tabs';
import DoubtStatsChart from '../components/Charts/DoubtStatsChart';
import SubmissionStatsChart from '../components/Charts/SubmissionStatsChart';
import ProgressPercentageChart from '../components/Charts/ProgressPercentageChart';
import DailyActiveUsersChart from '../components/Charts/DailyActiveUsersChart';
import RunSubmitStatsChart from '../components/Charts/JudgeStatsChart';
import RatingsChart from '../components/Charts/UserFeedbackChart';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { getDoubtStats, getSubmissionStats, getActiveUsersStats, getActiveUsersProgressStats } from '../services/operations/userStats';
import { getJudgeStats } from '../services/operations/JudgeStats';
import RatingsTabs from '../components/RatingsTabs';

const formatDate = (date) => {
    const year = date.getFullYear();
    // Months are zero-indexed in JavaScript
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

const StatsPage = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const dispatch = useDispatch();

     // Selectors for doubt stats
     const { activeUsers, totalDoubts, uniqueUsers, costByDay, totalCost, loadingDoubtStats, errorDoubtStats } = useSelector((state) => state.userStats);

     // Selectors for submission stats
     const { dailyMetrics, languageWiseSubmissions, loadingSubmissionStats, errorSubmissionStats} = useSelector((state) => state.userStats);

     // Selectors for active users summary
     const { activeUsersSummary, totalUsers, activeUsersToday, summaryLoading, summaryError} = useSelector((state) => state.userStats);

     // Selectors for active users progress summary
     const { activeUsersProgressSummary, progressSummaryLoading, progressSummaryError } = useSelector((state) => state.userStats);

     // Selector for judge stats
     const { runSubmitStats } = useSelector((state) => state.judgeStats);

    // State for date range
    const [startDate, setStartDate] = useState(() => {
        const now = new Date();
        return new Date(now.getFullYear(), now.getMonth(), 1); // Start of current month
    });
    const [endDate, setEndDate] = useState(new Date()); // Today

    useEffect(() => {
        // Convert dates to Unix timestamps in milliseconds
        const startTimestamp = startDate.getTime();
        const endTimestamp = endDate.getTime();

        // Format dates to YYYY-MM-DD
        const formattedStartDate = formatDate(startDate);
        const formattedEndDate = formatDate(endDate);

        // Dispatch actions to fetch stats
        dispatch(getDoubtStats(startTimestamp, endTimestamp));
        dispatch(getSubmissionStats(startTimestamp, endTimestamp));
        dispatch(getActiveUsersStats(formattedStartDate, formattedEndDate));
        dispatch(getActiveUsersProgressStats());
        dispatch(getJudgeStats(formattedStartDate, formattedEndDate));
    }, [startDate, endDate, dispatch]);

    // Combine the data for Doubt Stats Chart
    const combinedDoubtStats = activeUsers.map((user, index) => ({
        date: user.date,
        active_users: user.active_users,
        total_doubts: totalDoubts[index]?.total_doubts || 0,
        unique_users: uniqueUsers[index]?.unique_users || 0,
    }));

    return (
        <div className='flex dark:bg-dark font-primary h-screen overflow-hidden bg-[#fafafa]'>
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            <div className='relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden'>
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

                <div className='px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto'>
                    <h1 className='text-3xl font-bold mb-6 text-gray-800 dark:text-gray-200'>User Statistics Dashboard</h1>

                    <DateRangePicker
                        startDate={startDate}
                        endDate={endDate}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                    />

                    {/* Loading and Error States */}
                    { (loadingDoubtStats || loadingSubmissionStats || summaryLoading || progressSummaryLoading) && (
                        <p className='text-gray-500 dark:text-gray-400 mb-4'>Loading data...</p>
                    )}
                    { (errorDoubtStats || errorSubmissionStats || summaryError || progressSummaryError) && (
                        <p className='text-red-500 dark:text-red-400 mb-4'>
                            Error: {errorDoubtStats || errorSubmissionStats || summaryError || progressSummaryError}
                        </p>
                    )}

                    {/* Tabs for Different Stats */}
                    <Tabs>
                        {/* Tab 1: Doubt Stats */}
                        <div label="Doubt Stats">
                            <DoubtStatsChart data={combinedDoubtStats} />

                            <div className='mt-6'>
                                <h3 className='text-xl font-semibold text-gray-800 dark:text-gray-200'>Total Cost of Doubts: ${totalCost}</h3>
                            </div>
                        </div>

                        {/* Tab 2: Submission Stats */}
                        <div label="Submission Stats">
                            <SubmissionStatsChart data={dailyMetrics} />

                            <div className='mt-6'>
                                <h3 className='text-xl font-semibold mb-4 text-gray-800 dark:text-gray-200'>Language-wise Submissions</h3>
                                <ResponsiveContainer width="100%" height={400}>
                                    <PieChart>
                                        <Pie
                                            data={languageWiseSubmissions}
                                            dataKey="count"
                                            nameKey="language"
                                            cx="50%"
                                            cy="50%"
                                            outerRadius={150}
                                            fill="#8884d8"
                                            label
                                        >
                                            {languageWiseSubmissions.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={`#${Math.floor(Math.random()*16777215).toString(16)}`} />
                                            ))}
                                        </Pie>
                                        <Tooltip
                                            contentStyle={{
                                            backgroundColor: window.matchMedia('(prefers-color-scheme: dark)').matches ? '#333' : '#fff',
                                            borderRadius: '8px',
                                            }}
                                            itemStyle={{
                                            color: window.matchMedia('(prefers-color-scheme: dark)').matches ? '#fff' : '#000',
                                            }}
                                            labelStyle={{
                                            color: window.matchMedia('(prefers-color-scheme: dark)').matches ? '#fff' : '#000',
                                            }}
                                        />
                                        <Legend 
                                            wrapperStyle={{ color: window.matchMedia('(prefers-color-scheme: dark)').matches ? '#ccc' : '#333' }} 
                                        />
                                    </PieChart>
                                </ResponsiveContainer>
                            </div>
                        </div>

                        {/* Tab 3: Active Users */}
                        <div label="Active Users">
                            <DailyActiveUsersChart data={activeUsersSummary} />

                            <div className='mt-6 flex justify-between items-center'>
                                <h3 className='text-xl font-semibold text-gray-800 dark:text-gray-200'>Total Users: {totalUsers}</h3>
                                <h3 className='text-xl font-semibold text-gray-800 dark:text-gray-200'>Active Users Today: {activeUsersToday}</h3>
                            </div>
                        </div>

                        {/* Tab 4: User Progress */}
                        <div label="User Progress">
                            <ProgressPercentageChart data={activeUsersProgressSummary} />

                            <div className='mt-6'>
                                <h3 className='text-xl font-semibold text-gray-800 dark:text-gray-200'>Total Users: {totalUsers}</h3>
                            </div>
                        </div>

                        {/* Tab 5: Run & Submit Stats */}
                        <div label="Run & Submit Stats">
                            <RunSubmitStatsChart data={runSubmitStats} />
                        </div>

                        {/* Tab 6: User Feedback */}
                        <div label="User Feedback">
                            <RatingsChart />
                            <RatingsTabs />
                        </div>
                    </Tabs>
                </div>
            </div>
        </div>
    );
};

export default StatsPage;