import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import StoriesTable from '../../../components/Table/StoriesTable/StoriesTable';
import { getPendingStories, getApprovedStories, getDisapprovedStories } from '../../../services/operations/successStories';
import CategoryModal from '../../../components/Shared/Modals/CategoryModal';

const Stories = ({ category, startDate, endDate }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [content, setContent] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const dispatch = useDispatch();
    const { pending, approved, disapproved } = useSelector((state) => state.stories);

    useEffect(() => {
        if (category === 'pending') {
            dispatch(getPendingStories(startDate, endDate, currentPage));
        } else if (category === 'approved') {
            dispatch(getApprovedStories(startDate, endDate, currentPage));
        } else if (category === 'disapproved') {
            dispatch(getDisapprovedStories(startDate, endDate, currentPage));
        }
    }, [dispatch, currentPage, startDate, endDate, category]);

    const handleNextPage = () => {
        setCurrentPage(prevPage => prevPage + 1);
    };

    const handlePreviousPage = () => {
        setCurrentPage(prevPage => (prevPage > 1 ? prevPage - 1 : 1));
    };
   console.log("category: ", category);
   console.log("penging: ", pending)

const getCategoryStories = () => {
    if (category === 'pending') return pending;
    if (category === 'approved') return approved;
    if (category === 'disapproved') return disapproved;
    return [];
};

const renderCategoryStory = () => {
    const stories = getCategoryStories();

    return (
        <div className="mb-8">
            {stories.data && stories.data.length > 0 ? (
                <>
                    <StoriesTable 
                        setModalOpen={setModalOpen} 
                        setContent={setContent} 
                        stories={stories.data}
                        currentPage={currentPage}
                        startDate={startDate}
                        endDate={endDate}
                    />
                    <div className="flex justify-between mt-4">
                        <button
                            className="px-4 py-2 bg-gray-500 hover:bg-gray-700 text-white rounded"
                            onClick={handlePreviousPage}
                            disabled={currentPage <= 1}
                        >
                            Previous
                        </button>
                        <button
                            className="px-4 py-2 bg-blue-500 hover:bg-blue-700 text-white rounded"
                            onClick={handleNextPage}
                        >
                            Next
                        </button>
                    </div>
                </>
            ) : (
                <div className="flex flex-col gap-y-4 justify-center items-center text-3xl pt-4 dark:text-zinc-300">
                    No stories available for this category.
                <button
                        className="px-4 py-2 bg-gray-500 hover:bg-gray-700 text-white rounded "
                        onClick={handlePreviousPage}
                        disabled={currentPage <= 1}
                    >
                        Previous
                    </button>
            </div>
            )}
            <CategoryModal setModalOpen={setModalOpen} modalOpen={modalOpen}>
                <div>{content}</div>
            </CategoryModal>
        </div>
    );
};

return (
    <main className="dark:bg-dark font-primary h-screen overflow-y-auto bg-[#fafafa]">
        {renderCategoryStory()}
    </main>
);
};

export default Stories;