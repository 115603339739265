import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    notification: {},
    allNotifications: [],
    allPlusUpdates: [],
    plusUpdate: {},
};

const notificationSlice = createSlice({
    name: "notifications",
    initialState: initialState,
    reducers: {
        setNotification(state, value) {
            state.notification = value.payload;
        },
        setPlusUpdate(state, value) {
            state.plusUpdate = value.payload;
        },
        setAllNotifications(state, value) {
            state.allNotifications = value.payload;
        },
        setAllPlusUpdates(state, value) {
            state.allPlusUpdates = value.payload;
        },
    },
});

export const { setNotification, setAllNotifications, setPlusUpdate, setAllPlusUpdates } = notificationSlice.actions;
export default notificationSlice.reducer;