import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRecentRatings } from '../services/operations/userStats';

const RatingsTabs = () => {
    const [activeRating, setActiveRating] = useState(5);

    const dispatch = useDispatch();

    const ratingData = useSelector((state) => {
        switch (activeRating) {
            case 1:
                return state.userStats.oneRated;
            case 2:
                return state.userStats.twoRated;
            case 3:
                return state.userStats.threeRated;
            case 4:
                return state.userStats.fourRated;
            case 5:
                return state.userStats.fiveRated;
            default:
                return state.userStats.fiveRated;
        }
    });

    const { data: recentRatings, currentPage, totalRatings } = ratingData;
    const pageSize = 10;  // 10 ratings per page
    const totalPages = Math.ceil(totalRatings / pageSize);

    useEffect(() => {
        dispatch(getRecentRatings(activeRating, currentPage || 1));
    }, [activeRating, currentPage, dispatch]);

    const handleTabChange = (rating) => {
        console.log("handleTab")
        setActiveRating(rating);
        dispatch(getRecentRatings(rating, 1)); // Reset to page 1 when switching tabs
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            dispatch(getRecentRatings(activeRating, currentPage + 1));
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            dispatch(getRecentRatings(activeRating, currentPage - 1));
        }
    };

    return (
        <div className="w-full">
            {/* Tabs Component */}
            <div className="flex space-x-4 mb-4">
                {[1, 2, 3, 4, 5].map((rating) => (
                    <button
                        key={rating}
                        className={`px-4 py-2 font-semibold ${
                            activeRating === rating
                                ? 'bg-brand text-white'
                                : 'bg-gray-200 text-gray-600'
                        } rounded-lg`}
                        onClick={() => handleTabChange(rating)}
                    >
                        {rating}-Star
                    </button>
                ))}
            </div>

            {/* Display recent ratings */}
            <div className="mt-4">
                {recentRatings.length > 0 ? (
                    <div>
                        {recentRatings.map((rating, index) => (
                            <div
                                key={index}
                                className="border p-4 mb-2 rounded shadow-md flex flex-col"
                            >
                                {/* Top row with email and date */}
                                <div className="flex justify-between mb-2">
                                    <span className="text-xs text-gray-400">{rating.email}</span>
                                    <span className="text-xs text-gray-400">{new Date(rating.created_at).toLocaleString()}</span>
                                </div>

                                {/* Feedback Description */}
                                <div className="dark:text-gray-300 text-sm whitespace-pre-wrap overflow-auto">
                                    {rating.feedback}
                                </div>
                            </div>
                        ))}

                        {/* Pagination Controls */}
                        <div className="flex justify-between mt-4">
                            <button
                                className="px-4 py-2 bg-blue-500 text-white rounded disabled:bg-gray-300"
                                onClick={handlePreviousPage}
                                disabled={currentPage === 1}
                            >
                                Previous
                            </button>
                            <button
                                className="px-4 py-2 bg-blue-500 text-white rounded"
                                onClick={handleNextPage}
                                disabled={currentPage === totalPages}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                ) : (
                    <p>No ratings found for rating {activeRating}.</p>
                )}
            </div>
        </div>
    );
};

export default RatingsTabs;
