import React from 'react';
import { useDispatch } from 'react-redux';
import { updateStatus } from '../../../services/operations/successStories';

const StoriesTableItem = (props) => {
    const { setContent, setModalOpen, num, content, s_id, date, currentPage, status, startDate, endDate } = props
    const dispatch = useDispatch();
   
    const handleView = (e) => {
        e.stopPropagation();
        setModalOpen(true)
        setContent(content)
    }

    const handleStatusChange = (newStatus) => {
        try {
            dispatch(updateStatus(s_id, newStatus, startDate, endDate, currentPage));
        } catch (error) {
            console.error("Error updating status:", error);
        }
    };

    return (
    <>
        <tbody className="text-[13px] overflow-y-auto">
            <tr className='border-t-2 border-2 border-lead100 dark:border-lead500'>
                <td className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap text-center">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                        {num}.
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap text-center">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                        {new Date(date).toLocaleDateString()}
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                        {content.split('\n')[0].slice(0, 100)}...
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap text-center">
                    <div className="flex justify-center items-center text-zinc-700 dark:text-zinc-300 gap-x-4">
                        <button onClick={handleView} className="text-brand underline" type='button'>View</button>
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap text-center">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                        {status}
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap text-center">
                    <div className="flex justify-center items-center text-zinc-700 dark:text-zinc-300 gap-x-4">
                        <>
                            <button onClick={() => handleStatusChange("pending")} className="bg-gray-500 text-white px-3 py-1 rounded mr-2">
                                Pending
                            </button>
                            <button onClick={() => handleStatusChange("approved")} className="bg-green-500 text-white px-3 py-1 rounded mr-2">
                                Approve
                            </button>
                            <button onClick={() => handleStatusChange("disapproved")} className="bg-red-500 text-white px-3 py-1 rounded">
                                Disapprove
                            </button>
                        </>
                    </div>
                </td>
            </tr>
        </tbody>
    </>
    );
};

export default StoriesTableItem;
