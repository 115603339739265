/* eslint-disable */
import { ThumbsDown, ThumbsUp } from 'lucide-react';
import Prism from "prismjs";
import "prismjs/components/prism-c";
import "prismjs/components/prism-cpp";
import "prismjs/components/prism-java";
import "prismjs/components/prism-python";
import React, { useEffect, useState } from 'react';
import { isObjectEmpty } from '../../../utils/Utils';
import Sidebar from '../../../components/Sidebar/Sidebar';
import Header from '../../../components/Header/Header';

const TheoryPreview = () => {

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [activeBtn, setActiveBtn] = useState({ like: 0, dislike: 0 });
    const [totalLikes, setTotalLikes] = useState(9999);
    const [totalDisLikes, setTotalDisLikes] = useState(0);

    const [article, setArticle] = useState(null);

    useEffect(() => {
        const theory = localStorage.getItem('TheoryContent');
        if (theory) {
            setArticle(JSON.parse(theory));
        }
    }, []);


    useEffect(() => {
        Prism.highlightAll();
    }, [article?.content]);

    const handleScroll = (element) => {
        const offset = -100;
        const targetPosition = element.offsetTop + offset;
        setTimeout(() => window.scrollTo(0, targetPosition), 6000);
    }
    useEffect(() => {
        const codeSections = document.querySelectorAll("details.code-section");

        codeSections.forEach((detailsElement) => {
            if (detailsElement) {

                detailsElement.addEventListener("toggle", () => {

                    const codeTabs = detailsElement.querySelector(".code-tabs");
                    if (detailsElement.open) {
                        // Handle open state
                        if (codeTabs) {
                            const existingCopyButton = codeTabs.querySelector('.copy-button');
                            if (!existingCopyButton) {
                                const copyButton = document.createElement('button');
                                copyButton.className = 'copy-button';
                                copyButton.textContent = 'Copy';
                                codeTabs.appendChild(copyButton);
                            }
                        }
                    } else {
                        // Handle closed state
                        if (codeTabs) {
                            const copyButton = codeTabs.querySelector('.copy-button');
                            if (copyButton) {
                                copyButton.remove();
                            }
                        }
                    }
                });
            }
        });
    });
    useEffect(() => {
        const article = document.querySelector('.article');

        if (article) {
            const codeSection = article.querySelector('.code-section');

            if (codeSection) {
                const codeTabs = codeSection.querySelector('.code-tabs');

                if (codeTabs) {
                    const existingCopyButton = codeTabs.querySelector('.copy-button');

                    if (!existingCopyButton) {
                        const copyButton = document.createElement('button');
                        copyButton.className = 'copy-button';
                        copyButton.textContent = 'Copy';
                        codeTabs.appendChild(copyButton);
                    }
                }
            }
        }
    })

    useEffect(() => {
        const codeSections = document.querySelectorAll(".code-section");
        // Get last selected language from localStorage or set default value
        let lastSelectedLanguage =
            localStorage.getItem("lastSelectedLanguage") || "cpp";

        codeSections.forEach((section, sectionIndex) => {
            const codeTabs = section.querySelectorAll(".code-tab");
            const codeBlocks = section.querySelectorAll(".code-block");
            const copyBtn = section.querySelector(".copy-btn");
            const cpb = section.querySelector(".copy-button")

            if (copyBtn) copyBtn?.remove()


            codeTabs.forEach(
                (tab) => {
                    tab.addEventListener("click", () => {
                        codeTabs.forEach((t) => {
                            t.classList.remove("dsa_article_code_active");
                        });
                        tab.classList.add("dsa_article_code_active");

                        const lang = tab.dataset.lang;
                        const activeBlock = section.querySelector(
                            `.code-block[data-lang="${lang}"]`
                        );
                        codeBlocks.forEach((block) => {
                            if (block === activeBlock) {
                                block.classList.add("dsa_article_code_active");
                            } else {
                                block.classList.remove("dsa_article_code_active");
                            }
                        });

                        // Update last selected language for all sections and store in localStorage
                        lastSelectedLanguage = lang;
                        localStorage.setItem("lastSelectedLanguage", lang);
                        codeSections.forEach((otherSection, otherIndex) => {
                            if (otherIndex !== sectionIndex) {
                                const otherTabs = otherSection.querySelectorAll(".code-tab");
                                otherTabs.forEach((otherTab) => {
                                    if (otherTab.dataset.lang === lang) {
                                        otherTab.classList.add("dsa_article_code_active");
                                    } else {
                                        otherTab.classList.remove("dsa_article_code_active");
                                    }
                                });
                                const otherActiveBlock = otherSection.querySelector(
                                    `.code-block[data-lang="${lang}"]`
                                );
                                const otherCodeBlocks =
                                    otherSection.querySelectorAll(".code-block");
                                otherCodeBlocks.forEach((block) => {
                                    if (block === otherActiveBlock) {
                                        block.classList.add("dsa_article_code_active");
                                    } else {
                                        block.classList.remove("dsa_article_code_active");
                                    }
                                });
                            }
                        });
                    });
                },
                [article?.content]
            );

            if (cpb) {
                document.addEventListener('click', function (event) {
                    const copyButton = event.target.closest('.copy-button');
                    if (copyButton) {
                        const section = copyButton.closest('.code-section');
                        const activeBlock = section.querySelector(".code-block.dsa_article_code_active pre");
                        if (activeBlock) {
                            navigator.clipboard.writeText(activeBlock.innerText)
                                .then(() => {
                                    copyButton.textContent = "Copied";
                                    setTimeout(() => {
                                        copyButton.textContent = "Copy";
                                    }, 2000);
                                })
                                .catch((error) => {
                                    console.error('Unable to copy text: ', error);
                                    // Optionally, handle the error here
                                });
                        }
                    }
                });

            }
        });


        // Set initial state of the tabs
        codeSections.forEach((section) => {
            const codeTabs = section.querySelectorAll(".code-tab");
            const codeBlocks = section.querySelectorAll(".code-block");

            codeTabs.forEach((tab) => {
                if (tab.dataset.lang === lastSelectedLanguage) {
                    tab.classList.add("dsa_article_code_active");
                } else {
                    tab.classList.remove("dsa_article_code_active");
                }
            });

            codeBlocks.forEach((block) => {
                if (block.dataset.lang === lastSelectedLanguage) {
                    block.classList.add("dsa_article_code_active");
                } else {
                    block.classList.remove("dsa_article_code_active");
                }
            });
        });
    });
    useEffect(() => {

        const handleClick = (e) => {
            e.preventDefault();
            const target = e.target.hash;
            const element = document.querySelector(target);
            const bruteForceAlgorithmDetails = document.querySelector('#brute-force-approach details').querySelector('details');
            const betterAlgorithmDetails = document.querySelector('#better-approach details').querySelector('details');
            const optimalAlgorithmDetails = document.querySelector('#optimal-approach details').querySelector('details');
            const details = document.querySelectorAll('details');
            const videoDetails = document.querySelector("#dsa_article_video_explanation").querySelector('details')

            for (let j = 0; j < details.length; j++) {
                if (details[j].querySelector(`a[href="${target}"]`) === null) {
                    details[j].removeAttribute('open');
                    details[j].querySelector('summary').classList.remove('active');
                }
            }

            element?.querySelector('details')?.setAttribute('open', true);
            bruteForceAlgorithmDetails.setAttribute('open', true);
            betterAlgorithmDetails.setAttribute('open', true);
            videoDetails.setAttribute('open', true);
            optimalAlgorithmDetails.setAttribute('open', true);

            const links = document.querySelectorAll('a[href^="#"]');
            for (let j = 0; j < links.length; j++) {
                if (links[j].hash === target) {
                    links[j].classList.add('actively');
                    setTimeout(() => {
                        links[j].classList.remove('actively');
                    }, 3000);
                } else {
                    links[j].classList.remove('actively');
                }
            }
            handleScroll(element)


        };

        const links = document.querySelectorAll('a[href^="#"]');
        links.forEach(link => link.addEventListener('click', handleClick));

        return () => {
            links.forEach(link => link.removeEventListener('click', handleClick));
        };
    });


    return (
        <>
            <div className="flex font-primary h-screen overflow-hidden bg-[#fafafa] dark:bg-dark">
                <Sidebar  sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                    <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    <section className='px-4 sm:px-6 lg:px-8 py-8 md:w-4/5 w-full mx-auto 2xl:w-full'>
                        {/* {slug} */}
                        {article && !isObjectEmpty(article) ? (
                            <div className='flex flex-col'>
                                
                                <div className='flex sm:flex-row flex-col justify-between items-center mt-4'>
                                    <p className='text-[36px] font-bold font-amaranth'>{article?.problem_name}</p>
                                    <div className='flex gap-x-3 items-center'>
                                        <button>
                                            <ThumbsUp size={24} className={`${activeBtn.like === 1 ? 'fill-brand_50 stroke-brand_60 transition-all ease-in-out duration-300' : 'stroke-zinc-700'}`} />
                                        </button>
                                        <span>{totalLikes}</span>
                                        <button>
                                            <ThumbsDown size={24} className={`${activeBtn.dislike === 1 ? 'fill-indigo-500 stroke-indigo-600 transition-all ease-in-out duration-300' : 'stroke-zinc-700'}`} />
                                        </button>
                                        <span>{totalDisLikes}</span>
                                    </div>
                                </div>

                                <div className="text-lg article mt-4 font-medium text-zinc-800 dark:text-zinc-200 font-titillium" dangerouslySetInnerHTML={{ __html: article?.content }} />
                                {article?.video_link && (
                                    <div className='mt-4 flex justify-center items-center'>
                                        <iframe className="lazy-loaded" loading="lazy" width="810" height="456" data-lazy-type="iframe" data-src={`https://www.youtube.com/embed/${article?.video_link}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="" src={`https://www.youtube.com/embed/${article?.video_link}`}></iframe><noscript><iframe loading="lazy" width="810" height="456" src={`https://www.youtube.com/embed/${article?.video_link}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen=""></iframe></noscript>
                                    </div>
                                )}
                            </div>
                        ) : (null)}
                    </section>
                </div >

            </div>
        </>
    )
}

export default TheoryPreview