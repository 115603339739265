import React from 'react';
import StoriesTableItem from './StoriesTableItem';

const StoriesTable = ({ stories, setContent, setModalOpen, currentPage, startDate, endDate}) => {
    return (
        <div className="font-primary relative w-full">
            <div className='bg-white dark:bg-dark_50 rounded-t-xl'>
                <div className="overflow-x-auto">
                    <table className="table-auto w-full divide-y divide-gray-200 overflow-y-auto">
                        <thead className="text-xs uppercase text-[#8C8C8C]">
                            <tr>
                                <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div className="font-semibold text-center">No.</div>
                                </th>
                                <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-10 last:pr-10 py-3 whitespace-nowrap">
                                    <div className="font-semibold text-center">Date</div>
                                </th>
                                <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-10 last:pr-10 py-3 whitespace-nowrap">
                                    <div className="font-semibold text-center">Preview</div>
                                </th>
                                <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-10 last:pr-10 py-3 whitespace-nowrap">
                                    <div className="font-semibold text-center">Content</div>
                                </th>
                                <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-10 last:pr-10 py-3 whitespace-nowrap">
                                    <div className="font-semibold text-center">Status</div>
                                </th>
                                <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div className="font-semibold text-center">Action</div>
                                </th>
                            </tr>
                        </thead>
                            {
                                stories?.map((story, index) => {
                                    return (
                                        <StoriesTableItem
                                            setContent={setContent}
                                            setModalOpen={setModalOpen}
                                            num={index + 1}
                                            date={story.created_at}
                                            content={story.story}
                                            s_id={story.id}
                                            status={story.status}
                                            email={story.email}
                                            currentPage={currentPage}
                                            startDate={startDate}
                                            endDate={endDate}
                                        />
                                    )                
                                })
                            }
                    </table>
                </div>
            </div>
        </div>
    );
};

export default StoriesTable;
