import React, { useMemo } from 'react';
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';

const DoubtStatsChart = React.memo(({ data }) => {
    const chartData = useMemo(() => data.map(item => ({
        date: new Date(item.date).toISOString().split('T')[0],
        Active_Users: item.active_users,
        Total_Doubts: item.total_doubts,
        Unique_Users: item.unique_users,
    })), [data]);

    return (
        <div>
            <h3 className='text-xl font-semibold mb-4 text-gray-800 dark:text-gray-200'>Doubt Statistics Over Time</h3>
            <ResponsiveContainer width="100%" height={450}>
                <LineChart 
                    data={chartData}
                    margin={{ top: 20, right: 30, left: 20, bottom: 40 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis 
                        dataKey="date" 
                        stroke={window.matchMedia('(prefers-color-scheme: dark)').matches ? '#808080' : '#333'} 
                        tick={{ fontSize: 12, fill: window.matchMedia('(prefers-color-scheme: dark)').matches ? '#808080' : '#333' }} 
                        angle={-45}
                        textAnchor="end"
                        height={60}
                    />
                    <YAxis 
                        stroke={window.matchMedia('(prefers-color-scheme: dark)').matches ? '#808080' : '#333'} 
                        tick={{ fontSize: 12, fill: window.matchMedia('(prefers-color-scheme: dark)').matches ? '#808080' : '#333' }} 
                        allowDecimals={false}
                        domain={[0, 'dataMax + 10']} 
                        label={{ 
                            value: 'Number of Doubts', 
                            angle: -90, 
                            position: 'insideLeft', 
                            fill: window.matchMedia('(prefers-color-scheme: dark)').matches ? '#808080' : '#333' 
                        }}
                        padding={{ top: 20, bottom: 10 }}
                    />
                    <Tooltip 
                        contentStyle={{ backgroundColor: window.matchMedia('(prefers-color-scheme: dark)').matches ? '#333' : '#fff', color: window.matchMedia('(prefers-color-scheme: dark)').matches ? '#fff' : '#000' }} 
                    />
                    <Legend 
                        wrapperStyle={{ color: window.matchMedia('(prefers-color-scheme: dark)').matches ? '#ccc' : '#333' }} 
                    />
                    <Line type="monotone" dataKey="Active_Users" stroke="#8884d8" />
                    <Line type="monotone" dataKey="Total_Doubts" stroke="#82ca9d" />
                    <Line type="monotone" dataKey="Unique_Users" stroke="#ffc658" />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
});

export default DoubtStatsChart;
